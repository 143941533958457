import store from './store';

import axios from 'axios';

let baseURL = process.env.VUE_APP_API_URL || 'http://localhost:3000'
axios.defaults.baseURL = baseURL;
axios.interceptors.request.use(
  config => {
    if (config.baseURL === baseURL && !config.headers.Authorization) {
      const token = localStorage.getItem('fourcons-client-token');

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  error => Promise.reject(error)
);
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log('error axios', error)
    if (error.response.status === 401) {
      store.dispatch('logout');
    }
    return Promise.reject(error)
});

export default axios;