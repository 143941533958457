<template>
  <div class="container my-5">
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <div>
        <h1 class="h3 mb-2 text-gray-800">Detail Harga</h1>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">Halaman Utama</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/order' }">Order Saya</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: 'order.detail', params: { id: this.$route.params.id }}">Detail Order</el-breadcrumb-item>
          <el-breadcrumb-item>Detail Harga</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <!-- Content Row -->
    <div class="row">
      <div class="col-md-12">
        <el-tabs v-model="active_tab" @tab-click="handleClick">
          <el-tab-pane label="Semua mesin" name="all">
            <div class="content font-weight-bold">Ringkasan detail harga</div>
            <div class="content">
              <el-card class="my-3">
                <div class="font-weight-bold p-1">Biaya sewa mesin</div>
                <div class="d-flex justify-content-between p-1 mb-2">
                    <div>{{totalWorkDays}} hari</div>
                    <div>Rp {{formatFee(totalMachineFee)}}</div>
                  </div>
                <div class="font-weight-bold p-1">Biaya operator</div>
                <div class="d-flex justify-content-between p-1 mb-2">
                    <div>{{totalWorkDays}} hari</div>
                    <div>Rp {{formatFee(totalOperatorFee)}}</div>
                  </div>
                <div class="font-weight-bold p-1">Biaya transportasi</div>
                <div class="d-flex justify-content-between p-1">
                  <div>Mobilisasi dan demobilisasi</div>
                  <div>Rp {{formatFee(totalTransportationFee)}}</div>
                </div>
                <el-divider></el-divider>
                <div class="p-1 d-flex justify-content-between">
                  <div class="font-weight-bold">Total</div>
                  <div>Rp {{formatFee(totalFee)}}</div>
                </div>
              </el-card>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="acceptedMachineOrder.machine.name" :name="acceptedMachineOrder.machine.name" :key="indexAcceptedMachineOrder" v-for="(acceptedMachineOrder, indexAcceptedMachineOrder) in data.accepted_machine_orders">
            <div class="content font-weight-bold">Detail alat</div>
            <div class="content">
              <el-card class="my-3">
                <div class="d-flex justify-content-between p-1 mb-2">
                  <div>Nama mesin</div>
                  <div>{{acceptedMachineOrder.machine.name}}</div>
                </div>
                <div class="d-flex justify-content-between p-1 mb-2">
                  <div>Brand</div>
                  <div>{{acceptedMachineOrder.machine.brand}}</div>
                </div>
                <div class="d-flex justify-content-between p-1 mb-2">
                  <div>Produksi di</div>
                  <div>{{acceptedMachineOrder.machine.origin}}</div>
                </div>
                <div class="d-flex justify-content-between p-1 mb-2">
                  <div>Tahun produksi</div>
                  <div>{{acceptedMachineOrder.machine.production_year}}</div>
                </div>
              </el-card>
            </div>
            <div class="content font-weight-bold">Detail harga</div>
            <div class="content">
              <el-card class="my-3">
                <div class="font-weight-bold p-1">Biaya sewa mesin</div>
                <div class="d-flex justify-content-between p-1 mb-2">
                    <div>Rp {{formatFee(getMachineFeePerHour(acceptedMachineOrder.machine_fee))}} x 8 jam x {{totalWorkDays}} hari</div>
                    <div>Rp {{formatFee(acceptedMachineOrder.machine_fee)}}</div>
                  </div>
                <div class="font-weight-bold p-1">Biaya operator</div>
                <div class="d-flex justify-content-between p-1 mb-2">
                    <div>Rp {{formatFee(getOperatorFeePerDay(acceptedMachineOrder.operator_fee))}} x {{totalWorkDays}} hari</div>
                    <div>Rp {{formatFee(acceptedMachineOrder.operator_fee)}}</div>
                  </div>
                <div class="font-weight-bold p-1">Biaya transportasi</div>
                <div class="d-flex justify-content-between p-1">
                  <div>Mobilisasi dan demobilisasi</div>
                  <div>Rp {{formatFee(acceptedMachineOrder.transportation_fee)}}</div>
                </div>
                <el-divider></el-divider>
                <div class="p-1 d-flex justify-content-between">
                  <div class="font-weight-bold">Total</div>
                  <div>Rp {{formatFee(getTotalMachineOrderPrice(acceptedMachineOrder))}}</div>
                </div>
              </el-card>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<style>
  .content {
    font-size:0.875rem;
    line-height:1;
    margin-bottom: 1rem;
    color:#303133;
  }
</style>
<script>
import axios from '../../axios.js';

export default {
  name: 'order.detail',
  data: function() {
    return {
      data: {
        latitude:0,
        longitude:0,
        order_logs:[],
        accepted_machine_orders:[]
      },
      active_tab: 'all'
    }
  },
  computed: {
    totalWorkDays: function() {
      var work_start_date = new Date(this.data.work_start_date); 
      var work_end_date = new Date(this.data.work_end_date); 
      var difference_in_time = work_end_date.getTime() - work_start_date.getTime(); 
      var difference_in_days = difference_in_time / (1000 * 3600 * 24);
      return difference_in_days;
    },
    totalWorkHours: function() {
      return this.totalWorkDays * 8;
    },
    totalMachineFee: function() {
      let totalMachineFee = this.data.accepted_machine_orders.reduce(function (total, obj) {
        return total + parseFloat(obj.machine_fee);
      }, 0);
      return totalMachineFee;
    },
    totalOperatorFee: function() {
      let totalOperatorFee = this.data.accepted_machine_orders.reduce(function (total, obj) {
        return total + parseFloat(obj.operator_fee);
      }, 0);
      return totalOperatorFee;
    },
    totalTransportationFee: function() {
      let totalTransportationFee = this.data.accepted_machine_orders.reduce(function (total, obj) {
        return total + parseFloat(obj.transportation_fee);
      }, 0);
      return totalTransportationFee;
    },
    totalFee: function() {
      let totalFee = this.data.accepted_machine_orders.reduce(function (total, obj) {
        return total+ parseFloat(obj.machine_fee) + parseFloat(obj.operator_fee) + parseFloat(obj.transportation_fee);
      }, 0);
      return totalFee;
    }
  },
  mounted: function() {
    this.fetchOrder();
  },
  methods: {
    fetchOrder() {
      const loading = this.$loading();
      axios.get('/order/'+this.$route.params.id)
        .then((response) => {
          this.data = response.data.payload;
          loading.close();
        }).catch((err) => {
          loading.close();
          this.$router.push({name:'order.list'})
        })
    },
    handleClick(tab, event) {
      
    },
    getTotalMachineOrderPrice(machine_order) {
      return parseFloat(machine_order.machine_fee) + parseFloat(machine_order.operator_fee) + parseFloat(machine_order.transportation_fee);
    },
    getMachineFeePerHour(machine_fee) {
      return parseFloat(machine_fee) / 8 / this.totalWorkDays;
    },
    getOperatorFeePerDay(operator_fee) {
      return parseFloat(operator_fee) / this.totalWorkDays;
    },
    goToQuotationDetail() {
      this.$router.push({ name: 'order.quotation.detail', params: { id: this.$route.params.id }});
    },
    formatFee(fee) {
      return parseFloat(fee).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
  }
}
</script>