<template>
  <div class="container my-5">
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <div>
        <h1 class="h3 mb-2 text-gray-800">Dashboard</h1>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">Halaman Utama</el-breadcrumb-item>
          <el-breadcrumb-item>Dashboard</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    
    <!-- Content Row -->
    <div class="row">
      <div class="col-md-12">
        <h2 class="h6 mb-2 text-gray-800">Pesan alat anda</h2>
          <div class="col-md-12">
            <el-tabs v-model="active_category" @tab-click="handleClick" stretch=true>
              <el-tab-pane :label="category.name" :name="category.id.toString()" :key="index" v-for="(category, index) in categories">
                <div class="row">         
                  <!-- Machine Category -->
                  <div class="card-body col-md-2" style="cursor:pointer;" :key="index" @click="redirectDetail(subcategory.id)" v-for="(subcategory, index) in category.subcategories">
                    <div class="row">
                      <div class="col-md-12 d-flex">
                        <img style="height: 120px; width:100%; object-fit: contain; object-position:center;" :src="subcategory.photo" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <div class="d-flex align-items-center">
                          <h6 class="card-title mb-0" style="flex-grow: 1;">{{ subcategory.name }}</h6>                    
                        </div>
                        <div class="d-flex align-items-center">
                            <p class="m-0 text-dark" style="flex-grow: 1;">Sewa sekarang</p>                            
                        </div>
                      </div>
                    </div>
                  </div>  
                  <!-- End of Machine Category-->
                </div>
              </el-tab-pane>
            </el-tabs>    
          </div>
      </div>
    </div>


    <!-- Content Row -->
    <div class="row mt-5">
      <div class="col-md-12">
        <h2 class="h6 mb-2 text-gray-800">Pesanan anda</h2>
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="Status">
            <el-select v-model="form['search[status]']" placeholder="Status" @change="fetchOrders">
              <el-option label="Semua" value=""></el-option>
              <el-option label="Menunggu penawaran" value="created"></el-option>
              <el-option label="Menunggu konfirmasi" value="quoted"></el-option>
              <el-option label="Penawaran diterima" value="accepted"></el-option>
              <el-option label="Penawaran ditolak" value="rejected"></el-option>
              <el-option label="Order dibatalkan" value="canceled"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="col-md-12">
        <!-- <h2 class="h6 mb-2 text-gray-800">Hasil Pencarian</h2> -->
        <el-table
          :data="data.payload"
          stripe
          style="width: 100%">
          <el-table-column
            type="index"
            label="No"
            width="50">
          </el-table-column>
          <el-table-column
            label="Tanggal Proses"
            width="150">
            <template slot-scope="scope">
              {{formatISODate(scope.row.quotation_end_time)}}
            </template>
          </el-table-column>
          <el-table-column
            label="Alat yang Disewa"
            width="200">
            <template slot-scope="scope">
              <ul style="padding-left:0;">
                <li :key="index" v-for="(machine, index) in scope.row.order_subcategories">{{ machine.subcategory.name }}</li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column
            label="Lokasi Sewa"
            width="250">
            <template slot-scope="scope">
              {{scope.row.address}}<br/>
              {{scope.row.city}}, {{scope.row.province}}
            </template>
          </el-table-column>
          <el-table-column 
            label="Status"
            width="200">
            <template slot-scope="scope">
              <template v-if="scope.row.status == 'created'">
                <el-tag type="info">Menunggu penawaran</el-tag>
              </template>
              <template v-else-if="scope.row.status == 'quoted'">
                <el-tag>Menunggu konfirmasi</el-tag>
              </template>
              <template v-else-if="scope.row.status == 'accepted'">
                <el-tag type="success">Penawaran diterima</el-tag>
              </template>
              <template v-else-if="scope.row.status == 'rejected'">
                <el-tag type="danger">Penawaran ditolak</el-tag>
              </template>
              <template v-else-if="scope.row.status == 'canceled'">
                <el-tag type="warning">Order dibatalkan</el-tag>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="Waktu Sewa"
            width="250">
            <template slot-scope="scope">
              {{formatISODate(scope.row.work_start_date)}} s/d {{formatISODate(scope.row.work_end_date)}}
            </template>
          </el-table-column>
          <el-table-column
            label="Action"
            fixed="right"
            width="100">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleDetail(scope.$index, scope.row)">Detail</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="data.per_page"
          layout="total, prev, pager, next"
          :total="data.total"
          class="d-flex justify-content-end">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../../axios.js';
import moment from 'moment'
import machine_categories_list from '@/assets/definitions/machine_categories_list'

const baseURL = process.env.VUE_APP_API_URL || 'http://localhost:3000' 

export default {
  name: 'order.list',
  data: function() {
    return {
      data: {},
      currentPage: 1,
      form: {
        'search[status]': ''
      },
      active_category: '',
      disabledDate: {
        to: new Date(),
      },
      categories: [],
      data: {
        payload: [{
          photos: [],
          price_per_hour: 0
        }]
      },
    }
  },
  mounted: function() {
    this.fetchCategories();
    this.fetchOrders();
  },
  methods: {
    handleClick(tab, event) {
      
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    fetchCategories() {
      const loading = this.$loading();
      axios.get(baseURL+'/category/all')
        .then((response) => {
          loading.close();
          this.categories = response.data.payload
          this.active_category = this.categories[0].id.toString()
        })
        .catch((error) => {
          loading.close();
          console.log('fetch machine list error', error)
        })
    },
    redirectDetail(id) {
      this.$router.push({ name: 'rent.detail', params: { id }})
    },
    fetchOrders() {
      const loading = this.$loading();
      axios.get('/order/self', { params: this.form })
        .then((response) => {
          this.data = response.data;
          loading.close();
        }).catch((err) => {
          console.log(err);
        })
    },
    handleDetail(index, row) {
      this.$router.push({ name: 'order.detail', params: { id: row.id }})
    },
    handleDelete(index, row) { 
      axios.post('/order/delete', { _id: row._id })
        .then((response) => {
          this.data = response.data;
          this.fetchOrders()
        }).catch((err) => {
          console.log(err);
        })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.fetchCompanies()
    },
    formatISODate(val) {
      const date = moment(val).toDate();
      return date.toISOString().substring(0, 10);
    },
    formatISODateTime(val) {
      const date = moment(val).toDate();
      return date.toISOString().replace(/T|Z|\.\d{3}/g, ' ').trim();
    },
  }
}
</script>