import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import './element-ui'
import './vue-gmap'
import './vue-meta'

// Vue.config.productionTip = false

require('./bootstrap');

new Vue({
  el: '#app',
  components: { App },
  router,
  store,
  i18n
});
