<template>
  <div class="container my-5">
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <div>
        <h1 class="h3 mb-2 text-gray-800">Detail Order</h1>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">Halaman Utama</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/order' }">Order Saya</el-breadcrumb-item>
          <el-breadcrumb-item>Detail Order</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <!-- Content Row -->
    <div class="row">
      <div class="col-md-12">
        <el-tabs v-model="active_tab" @tab-click="handleClick">
          <el-tab-pane label="Informasi Dasar" name="basic">
            <div class="content font-weight-bold">No. Order</div>
            <div class="content">{{data.id}}</div>
            <div class="content font-weight-bold">Status</div>
            <div class="mb-3 content">
              <template v-if="data.status == 'created'">
                Menunggu penawaran
              </template>
              <template v-else-if="data.status == 'quoted'">
                Menunggu konfirmasi
              </template>
              <template v-else-if="data.status == 'accepted'">
                Penawaran diterima
              </template>
              <template v-else-if="data.status == 'rejected'">
                Penawaran ditolak
              </template>
              <template v-else-if="data.status == 'canceled'">
                Order dibatalkan
              </template>
            </div>
            <template v-if="data.status == 'quoted' || data.status == 'accepted'">
              <div class="content font-weight-bold">Detail Kontrak</div>
              <div class="d-flex justify-content-between">
                <div class="content">Kontrak untuk transaksi sewa menyewa</div>
                <el-link type="primary" href="https://storage.googleapis.com/fourcons_files/kontrak-fourcons-penyewa.docx.pdf" target="_blank">Buka kontrak</el-link>
              </div>
            </template>
            <template v-if="data.status == 'quoted'">
              <div class="content">Saya setuju dengan syarat dan ketentuan dalam kontrak sewa.</div>
              <el-form :rules="rules" ref="ruleForm" label-width="120px">
                <el-form-item prop="accept_term">
                  <el-radio-group v-model="accept_term">
                    <el-radio @click="updateAcceptTermTime"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </template>
            <div class="content font-weight-bold">Detail Mesin</div>
            <div class="content">
              <el-card class="my-3" :key="orderDetailIndex" v-for="(orderDetail,orderDetailIndex) in data.order_subcategories">
                <div class="font-weight-bold d-flex justify-content-between p-1">
                  <div>{{orderDetail.subcategory.name}}</div>
                  <div>{{orderDetail.count}} x</div>
                </div>
                <div class="d-flex justify-content-between p-1">
                  <div>{{orderDetail.subcategory.specification_name}}</div>
                  <div>{{orderDetail.specification}}</div>
                </div>
                <template v-if="data.status == 'quoted' || data.status == 'accepted'">
                  <div>
                    <div class="d-flex justify-content-between p-1">
                      <div>Harga</div>
                      <div>Rp {{ getQuotationPrice(orderDetail.subcategory_id) }}</div>
                    </div>   
                  </div>
                </template>
                <template v-else>
                  <div class="d-flex justify-content-center p-1">
                    <div class="font-weight-bold">Tidak ada penawaran</div>
                  </div>
                </template>
              </el-card>
            </div> 
            <template v-if="data.status == 'quoted' || data.status == 'accepted'">
              <div class="content font-weight-bold">Total Harga</div>
              <div class="content">Rp {{ getTotalPrice() }}</div> 
              <div class="content font-weight-bold">Aksi</div>
              <el-button size="small" type="primary" @click="goToQuotationDetail">Lihat detail harga dan spesifikasi alat</el-button>
            </template>
            <template v-if="data.status == 'quoted'">
              <el-button size="small" type="success" @click="acceptQuotation">Terima penawaran</el-button>  
              <el-button size="small" type="danger" @click="rejectQuotation">Tolak penawaran</el-button> 
            </template>
          </el-tab-pane>
          <el-tab-pane label="Lokasi Proyek" name="location">
            <div class="content font-weight-bold">Alamat Proyek</div>
            <div class="content">{{data.address}}</div>
            <div class="content font-weight-bold">Kota</div>
            <div class="content">{{data.city}}</div>
            <div class="content font-weight-bold">Provinsi</div>
            <div class="content">{{data.province}}</div>
            <GmapMap
              :center="{lat:parseFloat(data.latitude), lng:parseFloat(data.longitude)}"
              :zoom="10"
              map-type-id="terrain"
              style="width: 100%; height: 400px">
              <GmapMarker
                :position="{lat:parseFloat(data.latitude), lng:parseFloat(data.longitude)}"
                :clickable="false"
                :draggable="false"/>
            </GmapMap>
          </el-tab-pane>
          <el-tab-pane label="Waktu Sewa" name="time">
            <div class="content font-weight-bold">Awal Sewa</div>
            <div class="content">{{data.work_start_date}}</div>
            <div class="content font-weight-bold">Akhir Sewa</div>
            <div class="content">{{data.work_end_date}}</div>
            <div class="content font-weight-bold">Total Lama Sewa</div>
            <div class="content">{{totalWorkDays}} hari/{{totalWorkHours}} jam</div>
          </el-tab-pane>
          <el-tab-pane label="Detail Proyek" name="detail">
            <div class="content font-weight-bold">Area Pengerjaan Proyek</div>
            <div class="content">{{data.work_scope}}</div>
            <div class="content font-weight-bold">Detail Pekerjaan Alat</div>
            <div class="content">{{data.work_description}}</div>
          </el-tab-pane>
          <el-tab-pane label="Penggunaan Alat" name="usage" :disabled="data.status != 'accepted'">
            <div class="content">
              <el-card class="my-3" :key="acceptedMachineOrderIndex" v-for="(acceptedMachineOrder,acceptedMachineOrderIndex) in data.accepted_machine_orders">
                <div class="font-weight-bold d-flex justify-content-between p-1">
                  <div class="mb-2">{{acceptedMachineOrder.machine.name}}</div>
                  <div>{{acceptedMachineOrder.machine_status}}</div>
                </div>
                <div class="p-1 mb-2">
                  <div class="mb-1 font-weight-bold">Waktu sewa</div>
                  <div class="d-flex">
                    <el-progress class="flex-grow-1 my-1" :percentage="(acceptedMachineOrder.remaining_work_hours/acceptedMachineOrder.total_work_hours)*100" :show-text="false"></el-progress>
                    <div class="ml-2">Tersisa {{acceptedMachineOrder.remaining_work_hours}} jam</div>
                  </div>
                </div>
                <div class="p-1">
                  <div class="mb-1 font-weight-bold">Operator</div>
                  <template v-if="acceptedMachineOrder.operator">
                    <div>{{acceptedMachineOrder.operator.name}}</div>
                  </template>
                  <template v-else>
                    <div>Hubungi Fourcons untuk menambahkan operator</div>
                  </template>
                </div>
              </el-card>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Proses Kerja" name="logs" :disabled="data.status != 'accepted'">
            <template v-if="data.order_logs.length > 0">
              <el-timeline class="p-3">
                <el-timeline-item
                  v-for="(orderLog, indexOrderLog) in data.order_logs"
                  :key="indexOrderLog"
                  :type="orderLog.status == 'done' ? 'success' : ''"
                  :timestamp="orderLog.time">
                  {{orderLog.description}}
                </el-timeline-item>
              </el-timeline>
            </template>
            <template v-else>
              <div class="content">Hubungi Fourcons untuk menambahkan detail proses kerja.</div>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<style>
  .content {
    font-size:0.875rem;
    line-height:1;
    margin-bottom: 1rem;
    color:#303133;
  }
</style>
<script>
import axios from '../../axios.js';

export default {
  name: 'order.detail',
  data: function() {
    return {
      data: {
        latitude:0,
        longitude:0,
        order_logs:[]
      },
      active_tab: 'basic',
      accept_term_time: '',
      accept_term: false,
      rules: {
        accept_term: [
          { required: true, message: 'Silahkan baca kontrak sewa dan klik setuju', trigger: 'change' }
        ],
      }
    }
  },
  computed: {
    totalWorkDays: function() {
      var work_start_date = new Date(this.data.work_start_date); 
      var work_end_date = new Date(this.data.work_end_date); 
      var difference_in_time = work_end_date.getTime() - work_start_date.getTime(); 
      var difference_in_days = difference_in_time / (1000 * 3600 * 24);
      return difference_in_days;
    },
    totalWorkHours: function() {
      return this.totalWorkDays * 8;
    }
  },
  mounted: function() {
    this.fetchOrder();
  },
  methods: {
    fetchOrder() {
      const loading = this.$loading();
      axios.get('/order/'+this.$route.params.id)
        .then((response) => {
          this.data = response.data.payload;
          loading.close();
        }).catch((err) => {
          loading.close();
          this.$router.push({name:'order.list'})
        })
    },
    handleClick(tab, event) {
      
    },
    getQuotationPrice(order_subcategory_id) {
      let accepted_machine_orders = this.data.accepted_machine_orders.filter(function (el) {
        return el.machine.subcategory_id == order_subcategory_id 
      });
      let totalPrice = parseFloat(accepted_machine_orders[0].machine_fee) + parseFloat(accepted_machine_orders[0].operator_fee) + parseFloat(accepted_machine_orders[0].transportation_fee);
      return totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    getTotalPrice() {
      let totalPrice = this.data.accepted_machine_orders.reduce(function (total, obj) {
        return total+ parseFloat(obj.machine_fee) + parseFloat(obj.operator_fee) + parseFloat(obj.transportation_fee);
      }, 0);
      return totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    acceptQuotation() {
      const loading = this.$loading();
      axios.post('/order/machine_order/accept', { order_id: this.$route.params.id})
        .then((response) => {
          this.fetchOrder();
          loading.close();
        }).catch((err) => {
          loading.close();
        })
    },
    rejectQuotation() {
      const loading = this.$loading();
      axios.post('/order/machine_order/reject', { order_id: this.$route.params.id})
        .then((response) => {
          this.fetchOrder();
          loading.close();
        }).catch((err) => {
          loading.close();
        })
    },
    goToQuotationDetail() {
      this.$router.push({ name: 'order.quotation.detail', params: { id: this.$route.params.id }});
    }
  }
}
</script>