<template>
  <div class="container mt-4">
    <div class="row">

      <div class="col-md-12 my-2">
        <h1>Syarat dan Ketentuan</h1>
      </div>

      <div class="col-md-12 my-2 font-weight-bold">
        MOHON ANDA MEMERIKSA KETENTUAN PENGGUNAAN DAN KEBIJAKAN PRIVASI FOURCONS DENGAN SEKSAMA SEBELUM MENGUNDUH APLIKASI ATAU MENGGUNAKAN LAYANAN FOURCONS UNTUK PERTAMA KALI.
      </div>

      <div class="col-md-12 my-2">
        Dengan mengunduh, memasang, dan/atau menggunakan Aplikasi Fourcons (<span class="font-weight-bold">"Aplikasi"</span>), Anda setuju bahwa Anda telah membaca, memahami, menerima dan menyetujui Ketentuan Penggunaan ini (<span class="font-weight-bold">"Ketentuan Penggunaan"</span> atau <span class="font-weight-bold">"Perjanjian"</span>). Jika Anda tidak setuju dengan dengan Ketentuan Penggunaan (sebagaimana diuraikan di bawah ini) dan ingin menghentikan penggunaan Layanan, maka silahkan berhenti menggunakan Aplikasi dan/atau Layanan ini. Ketentuan Penggunaan ini merupakan suatu perjanjian sah antara Anda dan Fourcons berlaku pada kunjungan dan penggunaan Anda pada situs web Fourcons di domain fourcons.com.
      </div>

      <div class="col-md-12 my-2">
        Perusahaan berhak untuk mengubah Ketentuan Penggunaan atau kebijakan lainnya yang terkait dengan Layanan setiap saat. Perubahan pada Ketentuan Penggunaan atau Kebijakan Privasi yang berkaitan dengan Layanan akan efektif setelah di-posting pada versi terbaru di Situs Web. Anda dianggap telah menyetujui terhadap perubahan pada Ketentuan Penggunaan ini secara rutin meskipun Anda meninjau perubahan tersebut atau tidak.
      </div>

      <div class="col-md-12 my-2">
        <ol style="padding-inline-start:1.25rem">
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Definisi
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Fourcons"</span> berarti PT Pembangunan Integrasi Bangsa, suatu perseroan yang bergerak di bidang konstruksi dan didirikan berdasarkan hukum Negara Republik Indonesia berkedudukan dan berkantor pusat di Surabaya, dengan alamat di Jl. Darmo Permai Utara XI/27 RT.001/RW.007 Surabaya 60226, Indonesia.</li>
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Alat Berat"</span> berarti alat berat yang akan dikirim baik satu atau lebih yang dikirim sebagai bentuk Layanan.</li>
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Biaya Layanan Dasar"</span> berarti tarif pengiriman, sewa alat berat, dan jasa operator yang telah ditentukan oleh sistem harus dibayar kepada Penyedia Layanan sebagai biaya pengiriman dan sewa alat berat. Biaya Transportasi  dan sewa alat berat dasar wajib dipenuhi dan tidak dapat diubah secara sewenang-wenang oleh Penyedia Layanan dan/atau Pengguna Layanan.</li>
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Layanan"</span> berarti Layanan sewa alat berat dengan menggunakan alat yang ditawarkan oleh Penyedia Layanan melalui Situs Web dan Aplikasi yang dapat mencakup Layanan transportasi, sewa alat berat, dan/atau Layanan lainnya yang berkaitan dengan Layanan konstruksi.</li>
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Sistem"</span> mengacu kepada teknologi, Situs Web dan/atau Aplikasi.</li>
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Pengguna Layanan"</span> mengacu kepada setiap pelanggan dan/atau mitra kerja Fourcons yang mengunduh, memasang, mendaftar dan/atau menggunakan Aplikasi untuk memesan Layanan yang disediakan Fourcons.</li>
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Penyedia Layanan"</span> berarti suatu pihak ketiga sebagai mitra kerja Fourcons termasuk tetapi tidak terbatas pada agen, vendor, pemasok, kontraktor, sub-kontraktor, dan pihak lainnya yang memberikan Layanan kepada Fourcons, melakukan tugas dan/atau mewakili untuk dan atas nama Fourcons sehubungan dengan penyediaan Layanan melalui Situs Web dan Aplikasi.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Kewajiban pengguna layanan
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Pengguna Layanan menyatakan dan menjamin bahwa dirinya adalah individu yang secara hukum berhak untuk mengadakan perjanjian yang mengikat dan telah memenuhi ketentuan peraturan perundang-undangan yang berlaku khususnya Pasal 1320 Kitab Undang-Undang Hukum Perdata. Jika tidak, Fourcons berhak berdasarkan hukum untuk membatalkan perjanjian yang dibuat dengan Pengguna Layanan.</li>
              <li class="list-style-type-decimal">Pengguna Layanan wajib menjamin dan memastikan telah memiliki izin yang sah untuk melakukan pekerjaan di lokasi tersebut dan mengikatkan diri pada Ketentuan Penggunaan.</li>
              <li class="list-style-type-decimal">Pengguna Layanan wajib menjamin dan menyediakan pengamanan untuk menjaga keamanan alat dilokasi kerja beserta tempat parkir yang layak.</li>
              <li class="list-style-type-decimal">Pengguna Layanan wajib menyediakan kebutuhan Operator dan Helper berupa tempat tinggal di daerah proyek.</li>
              <li class="list-style-type-decimal">Pengguna Layanan wajib menyediakan Bahan Bakar Minyak (BBM) industri untuk keperluan operasi.</li>
              <li class="list-style-type-decimal">Pengguna Layanan memiliki kewajiban untuk menanggung segala kerugian dan/atau kerusakan yang dialami oleh Penyedia Layanan atau pihak lain sebagai akibat dari pelanggaran terhadap Ketentuan Penggunaan yang berlaku.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Larangan pengguna layanan
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Pengguna Layanan hanya menggunakan Situs Web dan/atau Aplikasi untuk tujuan yang dimaksud oleh Fourcons dan dilarang untuk mengubah, mengganti, memodifikasi atau melakukan kegiatan yang dapat membahayakan Situs Web dan/atau Aplikasi dengan cara apapun, baik yang berdampak permanen maupun sementara.</li>
              <li class="list-style-type-decimal">Pengguna Layanan tidak akan mengambil tindakan apapun yang dapat menyebabkan keterlambatan, gangguan, atau kerusakan sistem operasi Layanan maupun server jaringan yang terkait dengan Situs Web dan/atau Aplikasi.</li>
              <li class="list-style-type-decimal">Pengguna Layanan tidak akan membuat upaya untuk mengganggu, melemahkan, atau merusak Layanan atau usaha Fourcons, termasuk tetapi tidak terbatas pada tidak hadir pada titik pembongkaran atau tidak mempersiapkan lokasi untuk menerima alat berat pada tanggal dan waktu yang ditentukan oleh Pengguna.</li>
              <li class="list-style-type-decimal">Apabila Pengguna Layanan terbukti melakukan hal-hal yang dilarang sebagaimana disebutkan di atas atau terdapat dugaan penyalahgunaan dan/atau kecurangan yang berkaitan dengan penggunaan Situs Web dan/atau Aplikasi, Fourcons berhak untuk memberlakukan pembatasan-pembatasan yang diperlukan termasuk namun tidak terbatas pada membekukan akses, menutup akun, dan/atau melakukan tuntutan hukum sesuai dengan peraturan perundang-undangan yang berlaku.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Data pribadi pengguna layanan
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Pengguna Layanan menyatakan bahwa Informasi Pribadi yang diberikan oleh Pengguna Layanan adalah benar, lengkap dan akurat sesuai dengan keadaan yang sebenarnya.</li>
              <li class="list-style-type-decimal">Pengguna Layanan sepakat dan setuju memberikan izin kepada Fourcons untuk menggunakan Informasi Pribadinya untuk memindahkannya kepada Penyedia Layanan sehingga Layanan yang disediakan Fourcons dapat berjalan. Pengguna Layanan juga memberikan kebebasan kepada Fourcons untuk menyusun, mengumpulkan menyimpan dan memperbaharui data Pengguna Layanan sedemikian rupa, dalam jangka waktu yang diperlukan selama periode penyediaan Layanan Fourcons.</li>
              <li class="list-style-type-decimal">Pengguna Layanan sepakat dan setuju bahwa penyusunan, pengumpulan, penyimpanan, penggunaan dan pemindahan/transfer data Pengguna Layanan oleh Perusahaan telah diatur dalam <span class="font-weight-bold">"Kebijakan Privasi"</span> Fourcons, sebagaimana yang tertera dalam Situs Web. Pengguna Layanan dengan ini menyatakan dan mengakui telah membaca dan memahami Kebijakan Privasi sebelum menyetujui Ketentuan Penggunaan ini.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Kewajiban penyedia layanan
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Penyedia Layanan wajib memberikan Layanan semaksimal mungkin kepada Pengguna Layanan dan wajib untuk melakukan usaha dan tindakan semaksimal mungkin mencegah terjadinya kerugian dan/atau kecelakaan selama proses pengiriman dan pekerjaan  masa sewa.</li>
              <li class="list-style-type-decimal">Penyedia Layanan menjamin bahwa alat berat  yang disewakan dimiliki sah secara hukum dan karenanya bebas dari segala masalah dan gangguan yang timbul dalam bentuk apapun atau tuntutan dari pihak manapun selama berlangsungnya layanan.</li>
              <li class="list-style-type-decimal">Penyedia Layanan bertanggung jawab selama masa penyewaan alat, untuk memastikan alat berat selalu siap beroperasi, baik dari keperluan oli, perbaikan kerusakan, penggantian spare dan mekanik.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Pembayaran
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Biaya sewa akan dikenakan sesuai dengan Biaya Layanan Dasar. Biaya Layanan Dasar berlaku untuk pengiriman dan sewa alat berat beserta operatornya dengan spesifikasi, jumlah dan tarif yang tertera, dan akan dikenakan sesuai dengan informasi dan ketentuan yang diberikan oleh Penyedia Layanan untuk kepentingan Layanan.</li>
              <li class="list-style-type-decimal">Biaya Layanan Dasar dapat ditemukan pada Situs Web dan melalui Aplikasi. Fourcons dapat menyesuaikan atau mengubah atau memperbaharui Biaya Layanan Dasar dari waktu ke waktu.</li>
              <li class="list-style-type-decimal">Pengguna Layanan sepakat dan setuju membayar biaya pengiriman dan sewa alat berat secara penuh melalui sistem pembayaran yang disediakan oleh Fourcons, kecuali disepakati lain antara Pengguna Layanan dan Penyedia Layanan.</li>
              <li class="list-style-type-decimal">Fourcons memiliki hak menunda proses transaksi saat Fourcons cukup yakin bahwa transaksi yang dilakukan adalah sebuah tindakan yang bertentangan dengan hukum yang berlaku termasuk tetapi tidak terbatas pada tindakan penipuan, kejahatan dan/atau melibatkan aktivitas kriminal.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Pajak
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Pengguna Layanan sepakat dan setuju bahwa Perjanjian ini tunduk pada ketentuan hukum yang berlaku di bidang perpajakan.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Pernyataan dan jaminan
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Fourcons tidak menjamin keamanan database Fourcons dan Fourcons juga tidak dapat menjamin bahwa informasi yang Pengguna Layanan berikan tidak akan ditahan/terganggu saat sedang dikirimkan kepada Fourcons.</li>
              <li class="list-style-type-decimal">Fourcons tidak menyatakan dan menjamin ketepatan waktu, kualitas, kesesuaian, ketersediaan, akurasi atau kelengkapan dari Layanan, Situs Web dan/atau Aplikasi, termasuk namun tidak terbatas pada Layanan yang diperoleh atau berasal dari Penyedia Layanan melalui penggunaan Aplikasi tersebut.</li>
              <li class="list-style-type-decimal">
                Fourcons tidak menyatakan atau menjamin bahwa:
                <ol>
                  <li class="list-style-type-circle">Penggunaan Layanan dan/atau Aplikasi akan aman, tepat waktu, tanpa gangguan dan/atau terbebas dari kesalahan dan/atau beroperasi dengan kombinasi dengan perangkat keras lain, perangkat lunak, sistem atau data;</li>
                  <li class="list-style-type-circle">Layanan dan/atau Aplikasi akan memenuhi kebutuhan atau harapan Anda;</li>
                  <li class="list-style-type-circle">Setiap data yang tersimpan akan akurat atau dapat digunakan;</li>
                  <li class="list-style-type-circle">Kualitas produk, Layanan, informasi atau materi lain yang dibeli atau diperoleh oleh Anda melalui Aplikasi akan memenuhi kebutuhan atau harapan Anda;</li>
                  <li class="list-style-type-circle">Kesalahan atau kecacatan dalam Aplikasi akan diperbaiki;</li>
                  <li class="list-style-type-circle">Aplikasi atau server yang menyediakan Aplikasi terbebas dari virus atau komponen yang berbahaya lainnya; atau</li>
                  <li class="list-style-type-circle">Aplikasi dan/atau software dapat mengetahui posisi Anda atau transportasi yang digunakan oleh Penyedia Layanan.</li>
                </ol>
              </li>
              <li class="list-style-type-decimal">Anda sepakat dan setuju bahwa seluruh resiko yang timbul dari penggunaan Layanan oleh Anda adalah semata-mata dan sepenuhnya ada pada Anda dan Anda tidak akan memiliki hak untuk meminta ganti rugi dalam bentuk apapun dari Fourcons.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Pembatasan tanggung jawab
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Pengguna Layanan secara tegas mengesampingkan dan melepaskan Fourcons dari setiap dan semua kewajiban, tuntutan atau kerusakan yang timbul dari atau dengan cara apapun termasuk tetapi tidak terbatas pada kesalahan dan/atau kelalaian yang dilakukan oleh Pengguna Layanan saat memasukan data rekening bank sehingga mengakibatkan kesalahan dan/atau keterlambatan dalam proses finansial sehubungan dengan pemberian Layanan.</li>
              <li class="list-style-type-decimal">Perusahaan tidak akan menjadi pihak dalam sengketa, negosiasi sengketa antara Pengguna Layanan dan Penyedia Layanan. Tanggung jawab untuk keputusan yang Pengguna Layanan buat atas Layanan yang ditemukan melalui Aplikasi merupakan tanggung jawab dan melekat seutuhnya dengan dan pada Pengguna Layanan.</li>
              <li class="list-style-type-decimal">Kualitas Layanan yang diperoleh melalui penggunaan Aplikasi ini sepenuhnya menjadi tanggung jawab Penyedia Layanan yang pada akhirnya memberikan Layanan untuk Pengguna Layanan. Oleh karena itu, Pengguna Layanan memahami bahwa dengan menggunakan Aplikasi ini, Pengguna Layanan dapat dihadapkan pada persewaan alat berat yang berpotensi berbahaya, ofensif, tidak aman atau tidak pantas dan Anda menggunakan Layanan atas resiko Anda sendiri.</li>
              <li class="list-style-type-decimal">Fourcons tidak menyediakan peralatan bantu pengangkatan atau pemindahan dan sebagainya dan tidak termasuk dalam Layanan Fourcons. Pengguna Layanan bertanggung jawab untuk menyediakan peralatan bantu jika diperlukan.</li>
              <li class="list-style-type-decimal">Fourcons tidak bertanggung jawab kepada Pengguna Layanan atau pihak manapun atas biaya, bunga, kerusakan atau kerugian dalam segala jenis atau bentuk, termasuk cedera pribadi; gangguan emosi; atau hilangnya data, barang, pendapatan, keuntungan, penggunaan atau keuntungan ekonomi lainnya yang terjadi akibat Layanan, hal-hal yang berhubungan dengan pihak Penyedia Jasa, proses pengiriman alat, proses pekerjaan di masa sewa alat berat, serta penggunaan atau ketidakmampuan untuk menggunakan Layanan dan/atau Aplikasi.</li>
              <li class="list-style-type-decimal">Fourcons tidak bertanggung jawab atas setiap cedera, kematian, kerusakan, kerugian, atau kehilangan yang mungkin ditimbulkan atau disebabkan oleh perilaku para Penyedia Layanan atau Pengguna Layanan. Fourcons juga tidak bertanggung jawab atas kesalahan, termasuk pelanggaran lalu lintas, atau tindakan kriminal/melawan hukum yang berlaku yang dilakukan oleh Penyedia Layanan atau Pengguna Layanan selama pelaksanaan Layanan. Penyedia Layanan dan Pengguna Layanan hanya merupakan mitra kerja Fourcons, bukan pegawai/pekerja, agen atau perwakilan Fourcons.</li>
              <li class="list-style-type-decimal">Aplikasi ini dapat mengalami keterbatasan, penundaan dan masalah-masalah lain yang terdapat dalam penggunaan internet dan komunikasi elektronik, termasuk perangkat yang digunakan oleh Anda atau Penyedia Layanan rusak, tidak terhubung, berada diluar jangkauan, dimatikan atau tidak berfungsi. Fourcons tidak bertanggung jawab atas keterlambatan, kegagalan pengiriman dan proses sewa alat berat, kerusakan atau kerugian yang diakibatkan oleh masalah-masalah tersebut.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Ganti rugi
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">
                Pengguna Layanan setuju bahwa memberikan ganti rugi dan membebaskan Fourcons, pemberi lisensi, afiliasi, dan masing-masing dari petugas, direktur, komisaris, karyawan, pengacara dan agen Fourcons dari dan terhadap setiap dan semua klaim, biaya, kerusakan, kerugian, kewajiban dan biaya termasuk tetapi tidak terbatas pada biaya dan ongkos pengacara yang timbul dari atau sehubungan dengan:
                <ol>
                  <li class="list-style-type-decimal">penggunaan Layanan dan/atau Aplikasi oleh Pengguna Layanan termasuk hubungan Pengguna Layanan dengan Penyedia Layanan;</li>
                  <li class="list-style-type-decimal">pelanggaran atas tidak dipatuhinya salah satu Ketentuan Penggunaan atau peraturan perundang-undangan yang berlaku;</li>
                  <li class="list-style-type-decimal">pelanggaran terhadap hak-hak pihak ketiga, termasuk Penyedia Layanan pihak ketiga yang diatur melalui Aplikasi; atau</li>
                  <li class="list-style-type-decimal">penggunaan atau penyalahgunaan Aplikasi.</li>
                </ol>
              </li>
              <li class="list-style-type-decimal">Kewajiban pembelaan dan pemberian ganti rugi ini akan tetap berlaku walaupun Ketentuan Penggunaan dan penggunaan Situs Web dan/atau Aplikasi oleh Pengguna Layanan telah berakhir.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Pengakhiran
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Fourcons berhak untuk segera menangguhkan, membatasi atau menghentikan setiap kerja sama dan/atau penggunaan Aplikasi dalam hal terdapat alasan untuk mencurigai dan/atau patut untuk dicurigai adanya pelanggaran yang Pengguna Layanan lakukan terhadap ketentuan dari Ketentuan Penggunaan ini atau peraturan perundang-undangan yang berlaku.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Lain-lain
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Pengguna Layanan dengan ini memberikan izin kepada Fourcons untuk mengirimkan informasi mengenai Layanan atau Promosi kepada Pengguna Layanan dalam bentuk pesan elektronik mencakup namun tidak terbatas pada e-mail, SMS, pesan telepon otomatis, atau pemberitahuan lainnya pada Aplikasi di perangkat melalui saluran kontak yang diberikan Pengguna Layanan pada saat pendaftaran akun.</li>
              <li class="list-style-type-decimal">Ketentuan Penggunaan ini tunduk dan ditafsirkan berdasarkan hukum Negara Republik Indonesia.</li>
              <li class="list-style-type-decimal">Ketentuan Penggunaan ini dapat diubah sewaktu-waktu. Fourcons akan memberitahukan kepada Pengguna Layanan terkait perubahan yang terjadi. Penggunaan Aplikasi secara terus menerus setelah diterimanya pemberitahuan merupakan persetujuan dan penerimaan Pengguna Layanan terhadap perubahan tersebut.</li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>
<style type="scss">
  .list-style-type-upper-alpha {
    list-style-type:upper-alpha;
  }

  .list-style-type-decimal {
    list-style-type:decimal;
  }

  .list-style-type-circle {
    list-style-type:circle;
  }
</style>
<script>
export default {
  name: 'term.of.use',
  metaInfo() {
    return {
      title: 'Ketentuan penggunaan',
      meta: [
        { vmid: 'description', name: 'description', content: 'Syarat dan ketentuan penggunaan website dan aplikasi Fourcons' },
        { vmid: 'keyword', name: 'keyword', content: 'ketentuan penggunaan fourcons, term of use fourcons' }
      ]
    }
  },
}
</script>