<template>
    <div class="container section-padding px-5 my-4">
        <div class="row align-items-md-center justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-9">
                <div class="text-center">
                    <h1 class="text-gray-900 mb-4">Kontraktor Login</h1>
                </div>
                <el-form label-position="top" ref="form" :model="form" :rules="rules" class="user text-center" label-width="200px">
                    <el-alert
                    v-if="error"
                    :title="message"
                    type="error">
                    </el-alert>
                    <el-form-item prop="username">
                        <el-input v-model="form.username" placeholder="Username"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input show-password v-model="form.password" placeholder="Password"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="login" style="font-weight:900">Login</el-button>
                    </el-form-item>
                </el-form>
                <hr>
                <div class="text-left">
                    <router-link :to="{ name: 'register' }">Belum punya akun? Buat akun sekarang!</router-link>
                </div>
                <div class="text-left">                    
                    <a href="https://fourcons.com/management/" class="mt-2 d-inline-block">Masuk ke halaman login supplier</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'login',
  metaInfo() {
    return {
      title: 'Login kontraktor',
      meta: [
        { vmid: 'description', name: 'description', content: 'Halaman login untuk penyewaan alat berat dan/atau pembelian material konstruksi Fourcons' },
        { vmid: 'keyword', name: 'keyword', content: 'login fourcons, kontraktor fourcons' }
      ]
    }
  },
  data: function() {
    return {
        error: false,
        message: '',
        form: {
            username: '',
            password: ''
        },
        rules: {
            username: [
            { required: true, message: 'Please input your username', trigger: 'blur' },
            ],
            password: [
            { required: true, message: 'Please input your password', trigger: 'blur' },
            ],
        },
    }
  },
  methods: {
    login () {
      this.error = false;
      this.message = '';
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = this.$loading();
          this.$store.dispatch('login', this.form)
            .then(response => {
              loading.close();
            }, error => {
              loading.close();
              this.error = true;
              console.log(error)
              if (error.status == 400) {
                this.message = error.payload.message;
              }
              else if (error.status == 422)
                this.message = error.payload.errors;
              else
                this.message = "Internal Server Error";
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>