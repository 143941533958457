import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import About from './pages/About.vue'
import RentOut from './pages/RentOut.vue'
import Rent from './pages/Rent.vue'
import RentDetail from './pages/RentDetail.vue'
import Contact from './pages/Contact.vue'
import Login from './pages/Login.vue'
import Register from './pages/Register.vue'
import OrderList from './pages/Order/List.vue'
import OrderConfirmation from './pages/Order/Confirmation.vue'
import OrderDetail from './pages/Order/Detail.vue'
import OrderQuotationDetail from './pages/Order/QuotationDetail.vue'
import Checkout from './pages/Checkout.vue'
import NotFound from './pages/NotFound.vue'
import TermOfUse from './pages/TermOfUse.vue'
import PrivacyPolicy from './pages/PrivacyPolicy.vue'

import store from './store'

function requireAuth(to, from, next) {
    if (store.state.token)
    {
        if (to.name == 'checkout' && Object.keys(store.state.cart) == 0) {
            next('/order')
        }
        else {
            next();
        }
    }
    else {
      next('/login');
    }
}
  
function redirectIfAuthenticated(to, from, next) {
    if (store.state.token)
    {
        next('/order')
    }
    else {
      next()
    }
}

const routes = [
    {
        path: '*',
        redirect: {
            path: '/404'
        }
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: {
          public: true,
        },
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            public: true,
        },
    },
    {
        path: '/rent-out',
        name: 'rent-out',
        component: RentOut,
        meta: {
            public: true,
        },
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: {
            public: true,
        },
    },
    {
        path: '/',
        name: 'rent',
        component: Rent,
        meta: {
            public: true,
        },
    },
    {
        path: '/rent/:id',
        name: 'rent.detail',
        component: RentDetail,
        meta: {
            public: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            public: true,
        },
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            public: true,
        },
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/order',
        name: 'order.list',
        component: OrderList,
        beforeEnter: requireAuth
    },
    {
        path: '/order/:id',
        name: 'order.detail',
        component: OrderDetail,
        beforeEnter: requireAuth
    },
    {
      path: '/order/:id/quotation',
      name: 'order.quotation.detail',
      component: OrderQuotationDetail,
      beforeEnter: requireAuth
  },
    {
        path: '/order/confirmation',
        name: 'order.confirmation',
        component: OrderConfirmation,
        beforeEnter: requireAuth
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: Checkout,
        beforeEnter: requireAuth
    },
    {
      path: '/term-of-use',
      name: 'term.of.use',
      component: TermOfUse,
      meta: {
        public: true,
     },
    },
    {
      path: '/privacy-policy',
      name: 'privacy.policy',
      component: PrivacyPolicy,
      meta: {
        public: true,
      },
    },
];


const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes, // short for routes: routes
    scrollBehavior () {
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    store.dispatch('autoLogin');
    next();
});

export default router;
