<template>
    <div>
        <nav class="navbar navbar-expand-lg trans-navigation" :style="this.$route.name != 'rent' ? 'background:#111;' : ''"  :class="{ 'fixed-top' : this.$route.name == 'rent' }">
            <div class="container">
                <router-link :to="{ name: 'rent' }" class="navbar-brand smoth-scroll" style="text-align:center;">
                    <img style="height:50px" src="@/assets/images/logo_fourcons_white.png" alt="" class="img-fluid b-logo">
                </router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon">
                        <i class="fa fa-bars"></i>
                    </span>
                </button>

                <div class="collapse navbar-collapse justify-content-end" id="mainNav">
                    <ul class="navbar-nav ">
                        <li class="nav-item">
                            <router-link :to="{ name: 'rent' }" :class="{ 'active' : this.$route.name == 'rent' }" class="nav-link smoth-scroll" >{{ $t('layout.rent') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'rent-out' }" :class="{ 'active' : this.$route.name == 'rent-out' }" class="nav-link smoth-scroll" >{{ $t('layout.rent_out') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'about' }" :class="{ 'active' : this.$route.name == 'about' }" class="nav-link smoth-scroll" >{{ $t('layout.about') }}</router-link>
                        </li>
                        <li class="nav-item">   
                            <router-link :to="{ name: 'contact' }" :class="{ 'active' : this.$route.name == 'contact' }" class="nav-link smoth-scroll" >{{ $t('layout.contact') }}</router-link>
                        </li>
                        <template v-if="userData">
                            <!-- <li class="nav-item">   
                                <router-link :to="{ name: 'cart.list' }" :class="{ 'active' : this.$route.name == 'cart.list' }" class="nav-link smoth-scroll" >{{ $t('layout.cart') }}</router-link>
                            </li> -->
                            <li class="nav-item">   
                                <router-link :to="{ name: 'order.list' }" :class="{ 'active' : this.$route.name == 'order.list' }" class="nav-link smoth-scroll" >{{ $t('layout.order') }}</router-link>
                            </li>
                            <li class="nav-item">   
                                <a class="nav-link" href="javascript:void(0)" @click="logout">
                                    {{ $t('layout.logout') }}
                                </a>
                            </li>
                        </template>
                        <template v-else>
                            <li class="nav-item">   
                                <router-link :to="{ name: 'login' }" :class="{ 'active' : this.$route.name == 'login' }" class="nav-link smoth-scroll" >{{ $t('layout.login') }}</router-link>
                            </li>
                        </template>
                        <el-popover
                            placement="bottom"
                            width="300"
                            trigger="click"
                            v-model="isPopoverVisible"
                            class="nav-item">
                            <template v-if="cartCount === 0">
                                Keranjang belanja masih kosong.
                            </template>
                            <template v-else>
                                <div class="row mb-2" :key="index" v-for="(data, index) in cartData">
                                    <div class="col-4"><img :src="data.photo"></div>
                                    <div class="col-8">
                                        <h6>{{ data.name }}</h6> 
                                        <div>Spesifikasi: {{ data.specification }}</div> 
                                        <div>Jumlah: {{ data.count }}</div>
                                    </div>
                                </div>
                                <el-divider></el-divider>
                                <el-button type="danger" @click="clearCart" size="mini">Clear cart</el-button>
                                <el-button type="primary" @click="checkOut" size="mini">Checkout</el-button>
                            </template>
                            <li class="nav-item" slot="reference">   
                                <a class="nav-link smoth-scroll" >
                                    <el-badge :value="cartCount" class="item" type="info">
                                        <i class="fa fa-shopping-cart"></i>
                                    </el-badge>
                                </a>
                            </li>
                        </el-popover>
                        <!-- <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-globe mx-1"></i>
                                {{ transformLanguage }}
                            </a>
                            <div class="dropdown-menu" style="margin: 10px 30px">
                                <a class="dropdown-item " @click="changeLanguage('id-ID')">
                                    Indonesia
                                </a>
                                <a class="dropdown-item " @click="changeLanguage('en-US')">
                                    English
                                </a> 
                            </div>
                        </li> -->
                    </ul>
                </div>
            </div>
        </nav>

        <router-view :key="this.$route.fullPath"></router-view>

        <section id="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 d-flex flex-column flex-lg-row-reverse justify-content-lg-between mt-3 pt-3">
                        <div class="d-flex flex-column flex-lg-row justify-content-start mb-2">
                          <router-link :to="{ name: 'term.of.use' }" class="mx-2 hover-black">Syarat & Ketentuan</router-link>
                          <router-link :to="{ name: 'privacy.policy' }" class="mx-2 hover-black">Kebijakan Privasi</router-link>
                        </div>
                        <div style="letter-spacing: 1px;">
                            {{ $t('layout.footer') }}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<style lang="scss">
    @import '@/plugins/promodise/bootstrap/scss/bootstrap.scss';
    @import "vue-select/src/scss/vue-select.scss";

    .vdp-datepicker__calendar {
        position: absolute;
        right: 0px;
    }

    .hover-black:hover {
      color: #000;
    }

    .el-select {
      width: 100%;
    }
</style>
<style src="@/plugins/promodise/fonts/flaticon/flaticon.css"></style>
<style src="@/plugins/promodise/css/all.css"></style>
<style src="@/plugins/promodise/css/icofont.css"></style>
<style src="@/plugins/promodise/css/animate.min.css"></style>
<style src="@/plugins/promodise/css/style.css"></style>
<style src="@/plugins/promodise/css/responsive.css"></style>
<script>
export default {
  name: 'app',
  metaInfo: {
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s | Fourcons',
    // Define meta tags here.
    meta: [
      { vmid: 'description', name: 'description', content: '' },
      { vmid: 'keyword', name: 'keyword', content: '' }
    ]
  },
  data: function() {
    return {
        isPopoverVisible: false,
    }
  },
  computed: {
    transformLanguage() {
        switch (this.$i18n.locale) {
            case 'en-US':
                return 'English';
            case 'id-ID':
                return 'Indonesia';
        }
    },
    userData() {
        return !this.$store.getters.user ? false : this.$store.getters.user
    },
    cartData() {
        return !this.$store.getters.cart ? false : this.$store.getters.cart
    },
    cartCount() {
        return Object.keys(this.cartData).length
    }
  },
  methods: {
    changeLanguage(lang) {
        this.$i18n.locale = lang;
    },
    logout() {
        this.$store.dispatch('logout');
        this.$forceUpdate();
    },
    clearCart() {
        this.isPopoverVisible = false;
        this.$store.dispatch('clearCart')
            .then(response => {}, error => {});
    },
    checkOut() {
        this.isPopoverVisible = false;
        this.$router.push({ name: 'checkout' })
    }
  },
  mounted() {
      this.$store.dispatch('fetchUser');

      let vm = this;
      /* eslint-disable */
      $(window).on('scroll', function () {
        if (vm.$route.name == 'rent') {
            if ($(window).scrollTop() > 70) {
                $('.site-navigation,.trans-navigation').addClass('header-white');
            } else {
                $('.site-navigation,.trans-navigation').removeClass('header-white');
            }
        }
    });
    /* eslint-disable */
  }
}
</script>