<template>
    <div class="container my-5">
        <div class="row align-items-md-center justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-9">
                <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Client Area - Register</h1>
                </div>
                <el-form label-position="top" ref="form" :model="form" :rules="rules" class="user" label-width="200px">
                  <el-alert
                    v-if="error"
                    :title="message"
                    type="error">
                  </el-alert>
                  <el-form-item label="Username" prop="username">
                    <el-input v-model="form.username"></el-input>
                  </el-form-item>
                  <el-form-item label="Password" prop="password">
                    <el-input show-password v-model="form.password"></el-input>
                  </el-form-item>
                  <el-form-item required label="Password Confirmation" prop="password_confirmation">
                    <el-input show-password v-model="form.password_confirmation"></el-input>
                  </el-form-item>
                  <el-form-item label="Name" prop="name">
                    <el-input v-model="form.name"></el-input>
                  </el-form-item>
                  <el-form-item label="Telephone" prop="telephone">
                    <el-input v-model="form.telephone"></el-input>
                  </el-form-item>
                  <el-form-item label="Email" prop="email">
                    <el-input v-model="form.email"></el-input>
                  </el-form-item>
                  <el-form-item label="Files">
                    <el-upload
                      class="upload-demo"
                      action
                      multiple
                      :http-request="handleFileUpload"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :file-list="files"
                      :limit="5"
                      list-type="picture">
                      <el-button size="small" type="primary">Click to upload your related ID or your company files (ex: KTP/SIM/SIUP)</el-button>
                      <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 500kb</div>
                    </el-upload>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="register">Register</el-button>
                  </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import jsonToFormData from '@/assets/utils/jsonToFormData'

export default {
  name: 'register',
  metaInfo() {
    return {
      title: 'Pendaftaran kontraktor',
      meta: [
        { vmid: 'description', name: 'description', content: 'Halaman pendaftaran untuk penyewaan alat berat dan/atau pembelian material konstruksi Fourcons' },
        { vmid: 'keyword', name: 'keyword', content: 'register fourcons, daftar kontraktor fourcons' }
      ]
    }
  },
  data: function() {
    const validatePasswordConfirmation = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please confirm desired password'));
      } else if (value !== this.form.password) {
        callback(new Error('Password confirmation doesn\'t match'));
      } else {
        callback();
      }
    };
    return {
      error: false,
      message: '',
      form: {
        username: '',
        password: '',
        password_confirmation: '',
        name: '',
        telephone: '',
        files: [],
        email: '',
      },
      files: [],
      rules: {
        username: [
          { required: true, message: 'Please input desired username', trigger: 'blur' },
        ],
        password: [
          { required: true, message: 'Please input desired password', trigger: 'blur' },
        ],
        password_confirmation: [
          { validator: validatePasswordConfirmation, trigger: 'blur' },
        ],
        name: [
          { required: true, message: 'Please input your name', trigger: 'blur' },
        ],
        telephone: [
          { required: true, message: 'Please input your telephone', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Please input your email', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    register () {
      this.error = false;
      this.message = '';
      this.$refs.form.validate((valid) => {
        const input = jsonToFormData(this.form);
        if (valid) {
          const loading = this.$loading();
          this.$store.dispatch('register', input)
            .then(response => {
              loading.close();
            }, error => {
              loading.close();
              this.error = true;
              console.log(error)
              if (error.status == 422)
                this.message = error.payload.errors;
              else
                this.message = "Internal Server Error";
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleFileUpload(event) {
      this.form.files.push(event.file)
    },
  }
}
</script>