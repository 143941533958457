export default {
  'layout': {
    'rent': 'Produk',
    'rent_out': 'Supplier',
    'contact': 'Bantuan',
    'about': 'Tentang Fourcons',
    'footer': '© 2019 Fourcons. All Rights Reserved.',
    'cart': 'Cart',
    'order': 'Dashboard',
    'login': 'Login',
    'logout': 'Logout'
  },
  'about': {
    'header': {
      'introduction': 'Ingin sewa alat berat dengan mudah dan cepat?',
      'alert_success': 'Terima kasih, pesanan Anda sedang kami proses. Kami akan menghubungi anda dalam waktu 24 jam.',
      'alert_incomplete': 'Harap isi form dengan lengkap.',
      'alert_email_malformed': 'Harap email dengan benar.',
      'form_location': 'Lokasi proyek',
      'form_location_placeholder': 'Ketik lokasi kota terdekat proyek anda',
      'form_type': 'Jenis alat berat',
      'form_type_placeholder': 'Pilih jenis alat berat anda',
      'form_start_date': 'Tanggal mulai',
      'form_start_date_placeholder': 'Pilih tanggal mulai sewa',
      'form_end_date': 'Tanggal selesai',
      'form_end_date_placeholder': 'Pilih tanggal akhir sewa',
      'form_email': 'Email',
      'form_email_placeholder': 'Contoh@mail.com',
      'form_submit': 'DAPATKAN PENAWARAN TERBAIK'
    },
    'intro': {
      'introduction': 'Mencari persewaan alat berat yang terpercaya?',
      'problem_1_1': 'Untuk menyewa alat berat diperlukan proses pencarian, verifikasi dan perbandingan.',
      'problem_1_2': 'Hal ini membutuhkan waktu dan biaya yang cukup besar.',
      'solution_1_1': 'Tapi ini bisa menjadi cepat, mudah dan murah untuk anda.',
      'solution_1_2': 'Kami dapat membantu anda dalam:',
      'solution_list_1': 'mencari alat berat',
      'solution_list_2': 'mempermudah biaya finansial',
      'solution_list_3': 'menyediakan bantuan selama masa sewa',
      'action': 'DAPATKAN KONSULTASI GRATIS'

    },
    'about': {
      'introduction': 'Kenapa Fourcons?',
      'about_1': 'Hemat waktu dan mudah',
      'about_1_description': 'Anda dapat <b>mencari, membandingkan dan memilih alat berat </b>dengan mudah. Hal ini akan mempercepat pengambilan keputusan bisnis anda.',
      'about_2': 'Harga dan kualitas transparan',
      'about_2_description': 'Anda dapat <b>melihat seluruh rincian biaya-biaya yang dibutuhkan </b>beserta kualitas alat berat sebelum menyewa alat tersebut. Dengan ini, anda dapat mengambilkan keputusan dengan tepat.',
      'about_3': 'Cicilan hingga seusai proyek',
      'about_3_description': 'Anda dapat menggunakan jasa FourCons dan melakukan <b>pembayaran secara bertahap hingga setelah masa sewa alat berat anda usai</b>. Memperlancar dan mempermudah anda dalam mengatur keuangan.',
    },
    'benefit': {
      'introduction': 'Anda akan dapatkan dari FOURCONS',
      'description_1': '<b>Kualitas</b> alat berat dan <b>keamanan</b> proses sewa menyewa adalah prioritas kami.',
      'description_2': 'Kami telah <b>menyeleksi mitra</b> kami dengan standar yang tinggi.',
      'description_3': 'FourCons juga memberi anda kemampuan untuk <b>memantau secara langsung</b> melalui sistem kami.',
      'system_1': 'Memantau status transaksi.',
      'system_2': 'Memantau status pengiriman.',
      'system_3': 'Memantau status aktifitas alat berat.',
      'finance_header': 'Pembayaran bertahap sesuai kebutuhan cicilan anda:',
      'finance_1': 'Awal transaksi.',
      'finance_2': 'Akhir tanggal sewa.',
      'finance_3': 'Setelah waktu sewa usai.'
    },
    'contact': {
      'introduction': 'Ingin tahu lebih banyak?',
      'description': 'Kami ingin mengetahui apa yang anda butuhkan untuk alat berat anda dan kami siap membantu anda.',
      'action': 'DAPATKAN BANTUAN'
    },
    'support': {
      'introduction': 'DIDUKUNG OLEH'
    }
  },
  'rent_out': {
    'header': {
      'alert_success': 'Data telah berhasil disimpan, kami akan menghubungi Anda segera.',
      'alert_incomplete': 'Harap isi form dengan lengkap.',
      'alert_email_malformed': 'Harap email dengan benar.',
      'introduction': 'Dapatkan tambahan dengan menyewakan alat beratmu di FOURCONS',
      'form_telephone': 'Nomor telepon',
      'form_telephone_placeholder': 'Nomor telepon',
      'form_email': 'Email',
      'form_email_placeholder': 'Contoh@mail.com',
      'form_submit': 'Gabung Menjadi Supplier'
    },
    'intro': {
      'why_1': 'Kenapa bergabung dengan FOURCONS?',
      'why_1_description': 'Apapun jenis alat berat anda, umur alat berat anda, jangan biarkan terparkir dalam garasi. FourCons akan membantumu dari awal hingga usai persewaan.',
      'why_2': 'Jangan khawatir',
      'why_2_description': 'Untuk menjaga anda dari hal-hal yang tak diinginkan, kami telah memilih dan memeriksa customer-customer kami. Kepercayaan dan keamanan pada saat menyewa alat berat anda adalah prioritas kami. Kami siap membantu anda kapanpun.'
    },
    'step': {
      'introduction': 'LANGKAH MENYEWAKAN',
      'step_1': 'Daftarkan alat berat anda dengan gratis',
      'step_1_description': 'Bagikan alat berat anda di platform kami dengan gratis, mulai dari excavator, wheelloader, bulldozer, dll.',
      'step_2': 'Putuskan bagaimana cara anda menyewakan',
      'step_2_description': 'Atur jadwal dan harga alat berat anda dengan mudah.',
      'step_3': 'Sewakan alat beratmu',
      'step_3_description': 'Setelah alat beratmu terdaftar, anda akan segera mendapatkan pesanan dari pelanggan kami yang telah memenuhi syarat.',
      'step_3_action': 'Dapatkan info untuk menyewakan'
    },
    'about': {
      'introduction': 'PEMBAYARAN YANG MUDAH',
      'why_1': 'Tentukan biaya sesuai dengan keinginan anda',
      'why_1_description': 'Anda bisa menentukan harga alat berat anda berserta biaya-biaya lain yang diperlukan.',
      'why_2': 'Dapatkan pembayaran dengan cepat',
      'why_2_description': 'Kami akan mengirimkan uang, langsung ke rekening anda pada saat perjanjian sewa menyewa disetujui kedua pihak.'
    }
  },
  'contact': {
    'introduction': 'HUBUNGI KAMI',
    'description_1': 'Isi form berikut atau kirimkan email ke kami: ',
    'description_2': 'Menyewa alat berat sangatlah mudah. Mari berbincang mengenai kebutuhan anda.',
    'alert_success': 'Data telah berhasil disimpan, kami akan menghubungi Anda segera.',
    'alert_incomplete': 'Harap isi form dengan lengkap.',
    'alert_email_malformed': 'Harap email dengan benar.',
    'form_contact_name': 'Nama',
    'form_contact_name_placeholder': 'Isi nama anda',
    'form_company_name': 'Nama Perusahaan',
    'form_company_name_placeholder': 'Isi nama perusahaan anda',
    'form_telephone': 'No. Telp',
    'form_telephone_placeholder': 'Isi nomer telefon anda',
    'form_email': 'E-mail',
    'form_email_placeholder': 'Contoh@mail.com',
    'form_message': 'Apa yang dapat kami bantu?',
    'form_message_placeholder': 'Tuliskan apa yang ingin anda ketahui lebih lanjut.',
    'form_submit': 'Kirim',
  },
  'rent': {
    'form_location': 'Lokasi proyek',
    'form_location_placeholder': 'Ketik kota terdekat proyek anda',
    'form_category': 'Jenis alat berat',
    'form_category_placeholder': 'Pilih jenis alat berat anda',
    'form_start_date': 'Tanggal mulai',
    'form_start_date_placeholder': 'Pilih tanggal',
    'form_end_date': 'Tanggal selesai',
    'form_end_date_placeholder': 'Pilih tanggal',
    'search': 'CARI',    
  },
  'rent_detail': {
    'specification_title': 'Spesifikasi',
    'specification_brand': 'Merk / buatan',
    'specification_type': 'Tipe',
    'specification_year': 'Tahun pembuatan',
    'specification_SIA': 'SIA (Surat izin alat)',
    'specification_SILO': 'SILO (Surat izin layak operasi)',
    'specification_description': 'Deskripsi',
    'form_start_date': 'Tanggal mulai',
    'form_start_date_placeholder': 'Pilih tanggal',
    'form_end_date': 'Tanggal selesai',
    'form_end_date_placeholder': 'Pilih tanggal',
    'form_fee_detail': 'Rincian biaya dan pajak',
    'button_quote' : 'Dapatkan penawaran',
    'hour' : 'jam',
    'day' : 'hari',
    'shipping_address': 'Alamat',
    'shipping_address_placeholder': 'Masukkan alamat pengiriman',
    'shipping_receiver': 'Penerima',
    'shipping_receiver_placeholder': 'Masukkan nama penerima',
    'shipping_telephone': 'Telepon',
    'shipping_telephone_placeholder': 'Masukan nomor telepon',
    'work_scope': 'Bidang projek',
    'work_scope_placeholder': 'Contoh: konstruksi, tambang, perhutanan, dll',
    'work_description': 'Deskripsi penggunaan mesin',
    'work_description_placeholder': 'Contoh: gali tanah, dll'
  }
}