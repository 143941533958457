import Vue from 'vue'
import Vuex from 'vuex'
import axios from './axios';
import ElementUI from 'element-ui';

import router from './router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    userId: null,
    user: null,
    permission: null,
    notifications: [],
    tracking: null,
    order: null,
    cart: {}
  },
  mutations: {
    authUser(state, userData){
      state.token = userData.token
      state.userId = userData.userId
      state.permission = userData.permission
    },
    clearAuthData(state){
      state.token = null
      state.userId = null
      state.permission = null
      state.user = null
    },
    storeUser(state, user){
      state.user = user
    },
    storeOrder(state, order){
      state.order = order
    },
    storeOrderWorkingDetail(state, order){
      
    },
    storeOrderShippingDetail(state, order){

    },
    clearOrder(state) {
      state.order = null
    },
    setCart(state, cartData) {
      state.cart = cartData
    },
    addToCart(state, data){
      if ((data.subcategory_id+'-'+data.specification) in state.cart) {
        state.cart[data.subcategory_id+'-'+data.specification]['count'] += data.count;
      } else {
        state.cart[data.subcategory_id+'-'+data.specification] = {
          subcategory_id: data.subcategory_id,
          specification: data.specification,
          count: data.count,
          name: data.name,
          photo: data.photo,
          notes: data.notes 
        }
      }
      localStorage.setItem('fourcons-cart', JSON.stringify(state.cart))
    },
    clearCart(state) {
      state.cart = {}
      localStorage.setItem('fourcons-cart', JSON.stringify(state.cart))
    }
  },
  actions: {
    setLogoutTimer({dispatch}, expirationTime) {
      setTimeout(()=> {
        dispatch('logout')
      }, expirationTime * 1000);
    },
    autoLogin({commit,dispatch}){
      // set cart
      let cartData = {}
      if (localStorage.getItem('fourcons-cart'))
        cartData = JSON.parse(localStorage.getItem('fourcons-cart'))
      commit('setCart', cartData)

      // set user
      const token = localStorage.getItem('fourcons-client-token')
      if (!token) return
      const expirationDate = localStorage.getItem('fourcons-client-expirationDate')
      if (new Date(expirationDate) < new Date()) return
      const expiresIn = (new Date(expirationDate) - new Date().getTime()) / 1000;
      const userId = localStorage.getItem('fourcons-client-userId')
      const permission = localStorage.getItem('fourcons-client-permission')
      commit('authUser',{
        token: token,
        userId: userId,
        permission: permission
      })
      dispatch('setLogoutTimer', expiresIn)
    },
    login({commit, dispatch}, authData){
      return axios.post('/user/login', {
        username: authData.username,
        password: authData.password
      }).then(response => {
        commit('authUser', {
          token: response.data.token,
          userId: response.data.payload.id,
          permission: response.data.payload.role,
        })
        commit('storeUser', response.data.payload)
        const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000)
        if (new Date(expirationDate) < new Date()) return
        const expiresIn = (new Date(expirationDate) - new Date().getTime()) / 1000;
        localStorage.setItem('fourcons-client-token', response.data.token)
        localStorage.setItem('fourcons-client-userId', response.data.payload.id)
        localStorage.setItem('fourcons-client-permission', response.data.payload.role)
        localStorage.setItem('fourcons-client-expirationDate', expirationDate)
        dispatch('setLogoutTimer', expiresIn)
        router.push({ name: 'checkout' })
        return Promise.resolve();
      }).catch(error => {
        return Promise.reject({ status: error.response.status, payload: error.response.data });
      });
    },
    register({commit, dispatch}, data){
      return axios.post('/user/register', data, {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        commit('authUser', {
          token: response.data.token,
          userId: response.data.payload.id,
          permission: response.data.payload.role,
        })
        commit('storeUser', response.data.payload)
        const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000)
        localStorage.setItem('fourcons-client-token', response.data.token)
        localStorage.setItem('fourcons-client-userId', response.data.payload.id)
        localStorage.setItem('fourcons-client-permission', response.data.payload.role)
        localStorage.setItem('fourcons-client-expirationDate', expirationDate)
        router.push({ name: 'order.list' })
        return Promise.resolve();
      }).catch(error => {
        return Promise.reject({ status: error.response.status, payload: error.response.data });
      });
    },
    logout({commit}){
      commit('clearAuthData')
      localStorage.removeItem('fourcons-client-token')
      localStorage.removeItem('fourcons-client-userId')
      localStorage.removeItem('fourcons-client-permission')
      localStorage.removeItem('fourcons-client-expirationDate')
      ElementUI.Loading.service().close();
      router.replace({ name: 'login' })
    },
    fetchUser({commit, state}){
      if (!state.token) return
      axios.get('/user/auth')
        .then((response) => {
          commit('storeUser', response.data.payload)
        })
    },
    addToCart({commit}, data){
      commit('addToCart', data)
      router.push({ name: 'rent' })
      return Promise.resolve();
    },
    updateCart({commit}, data){

    },
    removeFromCart({commit}, data){

    },
    clearCart({commit}) {
      commit('clearCart')
    },
    previewOrderConfirmation({commit}, data){
      commit('storeOrder', data)
      router.push({ name: 'order.confirmation' })
      return Promise.resolve();
    },
    confirmPricingDetail({commit, dispatch}, data) {

    },
    confirmWorkingDetail({commit, dispatch}, data) {

    },
    confirmShippingDetail({commit, dispatch}, data) {

    },
    confirmOrder({commit, dispatch}, data){
      return axios.post('/order/create', data).then(response => {
        commit('clearCart')
        router.push({ name: 'order.list' })
        return Promise.resolve();
      }).catch(error => {
        return Promise.reject({ status: error.response.status, payload: error.response.data });
      });
    },
    clearOrder({commit}) {
      commit('clearOrder')
    }
  },
  getters: {
    user(state){
      return state.user
    },
    order(state) {
      return state.order
    },
    cart(state) {
      return state.cart
    }
  }
})
