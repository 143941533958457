<template>
  <div class="container my-5">
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h2 mb-0 text-gray-800">Buat pesanan</h1>
    </div>

    <div class="row my-2">
      <div class="col-lg-12">
        <el-steps :active="active" finish-status="success">
          <el-step title="Lokasi proyek"></el-step>
          <el-step title="Waktu sewa"></el-step>
          <el-step title="Detail proyek"></el-step>
        </el-steps>
      </div>
    </div>

    <!-- Content Row -->
    <template>
      <div class="row my-3" v-show="active==0">
        <div class="col-lg-12">
          <h4>Lokasi proyek</h4>
          <p>Masukkan alamat proyek dan geser peta hingga berada pada lokasi yang diinginkan.</p>
        </div>

        <div class="col-lg-12">
          <div class="row">
            <div class="col-md-12 col-sm-12 my-1">
              <el-form label-position="top" label-width="100px" :model="form" :rules="rulesProjectLocation" ref="formProjectLocation">
                <el-form-item label="Alamat proyek" prop="address">
                  <el-input v-model="form.address" placeholder="Masukkan alamat lengkap proyek"></el-input>
                </el-form-item>
                <el-form-item label="Provinsi" prop="province">
                  <el-select v-model="form.province" filterable placeholder="Masukkan provinsi proyek" @change="updateCitiesList">
                    <el-option
                      v-for="(province, index) in provinces_list"
                      :key="index"
                      :label="province"
                      :value="province">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Kota" prop="city">
                  <el-select v-model="form.city" filterable placeholder="Masukkan kota proyek">
                    <el-option
                      v-for="(city, index) in cities_list"
                      :key="index"
                      :label="city"
                      :value="city">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Pilih lokasi pengiriman pada peta">
                  <GmapMap
                  :center="{lat:-7.277936, lng:112.6840523}"
                  :zoom="10"
                  map-type-id="terrain"
                  @zoom_changed="updateMap('zoom', $event)" 
                  @center_changed="updateMap('reportedCenter', $event)"
                  @maptypeid_changed="updateMap('mapType', $event)" 
                  @bounds_changed="updateMap('bounds', $event)"
                  style="width: 100%; height: 400px">
                  <GmapMarker
                    :position="{lat:form.latitude, lng:form.longitude}"
                    :clickable="false"
                    :draggable="false"/>
                </GmapMap>
                </el-form-item>
                <el-form-item class="text-center">
                  <el-button type="primary" @click="nextStep">Lanjut</el-button>
                </el-form-item>
              </el-form>
            </div>   
          </div>
        </div>                            
      </div>
    </template>

    <template>
      <div class="row my-3"  v-show="active==1">
        <div class="col-lg-12">
          <h4>Waktu sewa</h4>
          <p>Pilih tanggal awal sewa (kedatangan alat) dan akhir sewa (penarikan alat)</p>
        </div>
        
        <div class="col-lg-12">
          <div class="row">
            <div class="col-md-12 col-sm-12 my-1">
              <el-form label-position="top" label-width="100px" :model="form" :rules="rulesRentTime" ref="formRentTime">
                <el-form-item label="Awal sewa" prop="work_start_date">
                  <el-date-picker
                    v-model="form.work_start_date"
                    type="date"
                    placeholder="Tanggal awal sewa"
                    style="width:100%"
                    :picker-options="startPickerOptions">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="Akhir sewa" prop="work_end_date">
                  <el-date-picker
                    v-model="form.work_end_date"
                    type="date"
                    placeholder="Tanggal akhir sewa"
                    style="width:100%"
                    :picker-options="endPickerOptions">
                  </el-date-picker>
                </el-form-item>
                <el-form-item class="text-center">
                  <el-button type="primary" @click="nextStep">Lanjut</el-button>
                  <el-button type="default" @click="previousStep">Back</el-button>
                </el-form-item>
              </el-form>
            </div>   
          </div>
        </div>
      </div>
    </template>

    <template>
      <div class="row my-3" v-show="active==2">
        <div class="col-lg-12">
          <h4>Detail pekerjaan</h4>
          <p>Penjelasan mengenai pekerjaan alat yang disewa</p>
        </div>
        
        <div class="col-lg-12">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <el-form label-position="top" label-width="100px" :model="form" :rules="rulesWorkDetail" ref="formWorkDetail">
                <el-form-item label="Area pengerjaan proyek" prop="work_scope">
                  <el-input type="textarea" :autosize="{ minRows: 3 }" v-model="form.work_scope" placeholder="Konstruksi, pertambangan, perkebunan, dll"></el-input>
                </el-form-item>
                <el-form-item label="Detail pekerjaan alat" prop="work_description">
                  <el-input type="textarea" :autosize="{ minRows: 3 }" v-model="form.work_description" placeholder="Menggali tanah sebesar 1 Hektar, menguruk tanah kapur 1000 Kubik, mengangkat beban 50 Ton"></el-input>
                </el-form-item>
                <el-form-item class="text-center">
                  <el-button type="primary" @click="submitOrder">Kirim order</el-button>
                  <el-button type="default" @click="previousStep">Back</el-button>
                </el-form-item>
              </el-form>
            </div>   
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import moment from 'moment';
import provinces_list from '@/assets/definitions/provinces_list'
import provinces_cities_list from '@/assets/definitions/provinces_cities_list'

export default {
  name: 'checkout',
  data: function() {
    return {
      startPickerOptions: {
        disabledDate: this.disabledStartDate
      },
      endPickerOptions: {
        disabledDate: this.disabledEndDate
      },
      shipping_address: '',
      active: 0,
      provinces_list: provinces_list,
      provinces_cities_list: provinces_cities_list,
      cities_list: provinces_cities_list['Aceh'],
      form: {
        address: '',
        city: '',
        province: 'Aceh',
        work_start_date: '',
        work_end_date: '',
        latitude: -7.277936,
        longitude: 112.6840523
      },
      rulesProjectLocation: {
        address: [
          { required: true, message: 'Silahkan masukkan alamat proyek', trigger: 'blur' },
        ],
        city: [
          { required: true, message: 'Silahkan masukkan kota proyek', trigger: 'change' },
        ],
        province: [
          { required: true, message: 'Silahkan masukkan provinsi proyek', trigger: 'blur' },
        ],
      },
      rulesRentTime: {
        work_start_date: [
          { required: true, message: 'Silahkan pilih tanggal kedatangan alat', trigger: 'change' },
        ],
        work_end_date: [
          { required: true, message: 'Silahkan pilih tanggal penarikan alat', trigger: 'change' },
        ],
      },
      rulesWorkDetail: {
        work_scope: [
          { required: true, message: 'Silahkan masukkan area pengerjaan proyek', trigger: 'blur' },
        ],
        work_description: [
          { required: true, message: 'Silahkan masukkan deskripsi proyek', trigger: 'blur' },
        ],
      },
    }
  },
  computed: {
    cartData() {
      return this.$store.getters.cart ? this.$store.getters.cart : false
    }
  },
  mounted: function() {
    if (Object.keys(this.cartData) == 0)
      this.$router.push({ name: 'rent' })
  },
  methods: {
    formatNumber(number) {
      if (!number) return 0;
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    submitOrder() {
      this.$refs.formWorkDetail.validate((valid) => {
        if (valid) {
          let data = {
            lists: Object.values(this.cartData),
            address: this.form.address,
            city: this.form.city,
            province: this.form.province,
            work_start_date: moment(this.form.work_start_date).format('YYYY-MM-DD HH:mm:ss'),
            work_end_date: moment(this.form.work_end_date).format('YYYY-MM-DD HH:mm:ss'),
            work_scope: this.form.work_scope,
            work_description: this.form.work_description,
            latitude: this.form.latitude,
            longitude: this.form.longitude,
            time: moment().format('YYYY-MM-DD HH:mm:ss')
          }
          console.log('order',data);
          this.$store.dispatch('confirmOrder', data);
        } else {
          return false;
        }
      });
    },
    cancelOrder() {
      this.$router.go(-1)
    },
    nextStep() {
      if (Object.keys(this.cartData) == 0)
        this.$router.push({ name: 'rent' })
      
      switch (this.active) {
        case 0:
          this.$refs.formProjectLocation.validate((valid) => {
            if (valid) {
              this.active++;
            } else {
              return false;
            }
          });
          break;
        case 1:
          this.$refs.formRentTime.validate((valid) => {
            if (valid) {
              this.active++;
            } else {
              return false;
            }
          });
          break;
        default:
          break;
      }
    },
    previousStep() {
      this.active--;
    },
    updateMap(field, event) {
      if (field == 'reportedCenter') {
        this.form.latitude = event.lat();
        this.form.longitude = event.lng();
      }
    },
    disabledStartDate(time) {
      return time.getTime() < Date.now();
    },
    disabledEndDate(time) {
      let disabledTime = this.form.work_end_date == '' ? Date.now() + 1000 * 60 * 60 * 24 : (new Date(this.form.work_end_date)).getTime();
      return time.getTime() < disabledTime;
    },
    updateCitiesList() {
      this.form.city = '';
      this.cities_list = this.provinces_cities_list[this.form.province];
    }
  }
}
</script>