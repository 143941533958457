<template>
  <div>
      <!--MAIN BANNER AREA START -->        
      <div class="d-table" id="join">
        <div class="d-table-cell banner-4">
            <div class="overlay dark-overlay"></div>
            <div class="container">
              <div class="row">
                <div class="col-lg-12 m-auto col-sm-12 col-md-12">
                    <div class="banner-content content-padding-2" style="min-height:90vh">
                        <!-- <h1 class="banner-title">{{ $t('rent_out.header.introduction') }}</h1>                        -->
                        <h1 class="banner-title">Solusi<br>Alat Berat dan<br>Material Pembangunan</h1>
                        <h5 class="subtitle text-left">Akses jaringan kontraktor se-Indonesia.<br>Tingkatkan produktifitas dan jangkauan bisnis anda.<br>Temukan dan terima pesanan dimanapun dan kapanpun.</h5>
                          <div class="row mt-3">
                            <div class="col-md-6">  
                              <el-form ref="form" :model="form" :rules="rules" class="rent_out" label-width="200px" label-position="top">
                                <el-alert
                                  v-if="status"
                                  :title="message"
                                  :type="type"
                                  class="mb-3">
                                </el-alert>
                                <el-form-item prop="telephone">
                                  <el-input v-model="form.telephone" :placeholder="$t('rent_out.header.form_telephone')"></el-input>
                                </el-form-item>
                                <el-form-item prop="email">                                  
                                  <el-input v-model="form.email" :placeholder="$t('rent_out.header.form_email')"></el-input>
                                </el-form-item>
                                <el-form-item>
                                  <el-button style="font-weight:900" type="primary" @click="submitForm">{{ $t('rent_out.header.form_submit') }}</el-button>
                                </el-form-item>
                              </el-form>                                   
                            </div>
                          </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <!--MAIN HEADER AREA END -->

      <!--  SERVICE AREA START  -->
      <section class="section-padding">
          <div class="container">
              <div class="row align-items-center">
                  <div class="col-lg-5 col-sm-12 col-md-6 mb-4">
                      <img src="@/assets/images/display3.jpg" alt="intro-img" class="img-fluid d-none d-sm-none d-md-none d-lg-block" style="height: 500px; width:100%; object-fit: cover; object-position:center;">
                  </div>

                  <div class="col-lg-7 pl-4">
                    <div class="mb-5">
                          <h3 class="mb-4">Kenapa Bergabung Dengan Fourcons?</h3>
                          <p>Fourcons memasarkan kepada pelanggan di seluruh Indonesia dan di berbagai industri. 
                            Melalui kehadiran jaringan online kami, kami dapat menjangkau pelanggan di industri non-tradisional lainnya.
                            Jadilah mitra Fourcons dan dapatkan:</p>
                      </div>

                      <ul class="about-list">
                          <li> <h5 class="mb-2"><i class="icofont icofont-check-circled"></i>Pesanan yang lebih banyak</h5>
                              <p>Temukan dan terima pesanan dimanapun dan kapanpun.</p>
                          </li>

                          <li>
                              <h5 class="mb-2"><i class="icofont icofont-check-circled"> </i>Mitra Terpercaya</h5>
                              <p>Bekerja sama dengan kontraktor yang telah terverifikasi.</p>
                          </li>

                          <li>
                              <h5 class="mb-2"><i class="icofont icofont-check-circled"> </i>Pembayaran Langsung</h5>
                              <p>Dapatkan pembayaran pada waktu 5x24 jam setelah bukti pengiriman pesanan diterima.</p>
                          </li>
                          <li>
                              <h5 class="mb-2"> <i class="icofont icofont-check-circled"> </i>Kemudahan Manajemen</h5>
                              <p>Akses sistem management supply dan olah seluruh proses supply chain di genggaman anda. </p>
                          </li>
                      </ul>
                      <a href="" @click="goToContact" class="mt-3 d-inline-block">Cari tahu lebih lanjut <i class="fa fa-angle-right"></i></a>
                  </div>
              </div>
          </div>
      </section>
      <!--  SERVICE AREA END  -->
      
      <!--  STEP AREA START  -->
      <section id="step" class="section-padding bg-dark-blue">
          <div class="container">
            <h1 class="text-center text-white mb-4"> Cara Mengambil Pesanan </h1>
              <div class="row justify-content-center text-white">
                  <div class="col-lg-3 col-md-6">
                      
                      <div class="service-box-2">                            
                          <span>01</span>                          
                          <img src="@/assets/images/tap-2.png" alt="intro-img" class="ml-5 mb-3 img-fluid d-none d-sm-none d-md-none d-lg-block" style="width:80px; height:80px;filter: brightness(0) invert(1);">
                          <h4 class="text-white">Pilih<br>Pesanan</h4>
                          <p class="mt-3">Pilih pesanan dan analisa lokasi proyek, tanggal pengiriman dan detil pekerjaan proyek.</p>
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                      <div class="service-box-2">
                          <span>02</span>
                          <img src="@/assets/images/clipboard.png" alt="intro-img" class="ml-5 mb-3 img-fluid d-none d-sm-none d-md-none d-lg-block" style="width:80px; height:80px;filter: brightness(0) invert(1);">
                          <h4 class="text-white">Ajukan<br>Penawaran Harga</h4>
                          <p class="mt-3">Masukkan harga penawaranmu beserta detil informasi penawaran lainnya.</p>                            
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                      <div class="service-box-2">
                          <span>03</span>
                          <img src="@/assets/images/file.png" alt="intro-img" class="ml-5 mb-3 img-fluid d-none d-sm-none d-md-none d-lg-block" style="width:80px; height:80px;filter: brightness(0) invert(1);">
                          <h4 class="text-white">Tunggu<br>Tender Penawaran</h4>
                          <p class="mt-3">Dalam waktu beberapa jam, kontraktor akan memilih penawaran yang masuk.</p>
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                      <div class="service-box-2">
                          <span>04</span>
                          <img src="@/assets/images/contract.png" alt="intro-img" class="ml-5 mb-3 img-fluid d-none d-sm-none d-md-none d-lg-block" style="width:80px; height:80px;filter: brightness(0) invert(1);">
                          <h4 class="text-white">Kerja sama<br>dengan Kontraktor</h4>
                          <p class="mt-3">Pembuatan kontrak kerja dan jalankan pekerjaan sesuai dengan yang telah ditentukan.</p>
                      </div>
                  </div>                  
              </div>
          </div>
      </section>
      <!--  STEP AREA END  -->

      <!--  SECTION Service-2START  -->
<section class="section-padding" id="supplier-apps">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
              <h1 class="text-left mt-4 mb-5">Aplikasi<br>Manajemen Fourcons</h1>
                <div class="row">                
                    <div class="col-lg-6">
                        <div class="mb-5">
                            <span class="icon-3x text-default"><i class="icofont icofont-ui-clip-board" style="filter: brightness(0) invert(0);"></i></span>
                            <h4 class="my-3">Terima pesanan dimanapun dan kapanpun</h4>
                            <!-- <p>Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. </p> -->
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-5">
                            <span class="icon-3x text-default"><i class="icofont icofont-ui-office" style="filter: brightness(0) invert(0);"></i></span>
                            <h4 class="my-3">Pantau aktifitas pesanan secara langsung</h4>
                            <!-- <p>Override the digital divide with additional clickthroughs from DevOps. Nanotechnology </p> -->
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div>
                            <span class="icon-3x text-default"><i class="icofont icofont-articulated-truck" style="filter: brightness(0) invert(0);"></i></span>
                            <h4 class="my-3">Manajemen Aset</h4>
                            <!-- <p>Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. </p> -->
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div>
                            <span class="icon-3x text-default"><i class="icofont icofont-document-folder" style="filter: brightness(0) invert(0);"></i></span>
                            <h4 class="my-3">Laporan elektronik</h4>
                            <!-- <p>Override the digital divide with additional clickthroughs from DevOps. Nanotechnology </p> -->
                        </div>
                    </div>
                </div>
                <div class="mt-3 mb-3">
                  <el-button style="font-weight:900" type="primary" @click="goToForm">{{ $t('rent_out.header.form_submit') }}</el-button>
                </div>
            </div>
            <div class="col-lg-4">
              <img src="@/assets/images/apps-supplier.png" alt="intro-img" class="img-fluid d-none d-sm-none d-md-none d-lg-block" style="height:500px">

              <div class="text-center mt-4">
                <a href="https://play.google.com/store/apps/details?id=fourcons.supplier" target="_blank"><img src="@/assets/images/google-play-badge.png" alt="intro-img" class="img-fluid" style="width:42%"></a>
                <a href="https://apps.apple.com/id/app/fourcons-supplier/id1499254708" target="_blank" style="margin:20px"><img src="@/assets/images/app-store-badge.png" alt="intro-img" class="img-fluid" style="width:40%"></a>
              </div>
              
            </div>
        </div>
    </div>
</section>
<!--  SECTION Service-2 END  -->

  </div>
</template>
<style>
  .rent_out .el-form-item__label {
    color: white !important;
  }
</style>
<script>
import axios from 'axios';

export default {
  name: 'rent-out',
  metaInfo() {
    return {
      title: 'Rental alat berat atau Supplier material konstruksi',
      meta: [
        { vmid: 'description', name: 'description', content: 'Fourcons memberikan solusi alat berat dan material konstruksi. Bergabung dengan Fourcons untuk akses jaringan kontraktor di seluruh Indonesia, tingkatkan produktivitas dan jangkauan bisnis konstruksi Anda.' },
        { vmid: 'keyword', name: 'keyword', content: 'sewa alat berat, beli material, rental alat berat, supplier material, kontraktor, konstruksi, pertambangan, indonesia, sewa excavator, sewa bulldozer, sewa crane, beli pasir, beli kayu, beli baja, beli material konstruksi' }
      ]
    }
  },
  data: function() {
    return {
      form: {
        telephone: '',
        email: ''
      },
      rules: {
        telephone: [
          { required: true, message: 'Silahkan masukkan nomor telepon', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Silahkan masukkan email', trigger: 'blur' },
          { type: 'email', message: 'Silahkan masukkan email yang benar', trigger: ['blur', 'change'] }
        ],
      },
      status: false,
      message: '',
      type: ''
    }
  },
  methods: {
    submitForm() {
      this.status = 0,
      this.message = '';
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = this.$loading();
          return axios.post('/supplier_contact/create', this.form).then(response => {
            loading.close();
            this.status = true;
            this.message = 'Berhasil menghubungi Fourcons. Kami akan segera menghubungi Anda.';
            this.type = 'success';
            this.clearForm();
          }).catch(error => {
            loading.close();
            this.status = true;
            this.type = 'error';
            if (error.response.status == 429)
              this.message = 'Terlalu banyak mengirimkan pesan. Silahkan tunggu beberapa saat.';
            else
              this.message = 'Internal Server Error';
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    clearForm() {
      this.form.email = '';
      this.form.telephone = '';
    },
    clearAlert() {
      this.status = false;
      this.message = '';
      this.type = '';
    },
    goToContact() {
      this.$router.push({ name: 'contact' });
    },
    goToForm() {
      const element = document.getElementById('join');
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>