<template>
    <div>
        <!--MAIN BANNER AREA START -->
        <div class="page-banner-area section-padding" style="min-height:90vh">
            <!-- <div class="overlay dark-overlay"></div> -->
            <div class="col-lg-5 col-md-6 text-center mt-5">
                <img src="@/assets/images/logo_fourcons_black.png" alt="intro-img" class="img-fluid" style="width:75%">
                <h3 class="mt-4">One Stop Solution for Construction</h3>
            </div>            
        </div>
        <!--MAIN HEADER AREA END -->
        <!--  HISTORY AREA  -->
        <section class="section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 ">
                        <div class="media img-block mb-3 mb-lg-0">
                            <img src="@/assets/images/about1.jpg" alt="" class="img-fluid rounded mr-3" style="height:150px">
                            
                            <div class="media-body ">
                                <h2 class="mb-3">Visi kami</h2>
                                <h4>Menjadi solusi kontraktor terbesar di Indonesia.</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="media img-block">
                            <img src="@/assets/images/display2.jpg" alt="" class="img-fluid rounded mr-3" style="height:150px">
                        
                            <div class="media-body">
                                <h2 class="mb-3">Misi kami</h2>
                                <h4>Meningkatkan kinerja dan efisiensi bisnis.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--  HISTORY AREA END  -->

        <!--  ABout2  AREA START  -->
        <section class="section-padding bg-dark-blue">
            <div class="container">
                <div class="row align-items-center text-white">
                    <div class="col-lg-7 col-sm-12 col-md-8 mb-4">
                        <h1 class="mb-3 text-white">Kami menghubungkan kontraktor dengan supplier</h1>
                        <p class="mb-4 text-white">Di Fourcons, kami yakin ada cara yang lebih baik untuk memajukan bisnis konstruksi. Fourcons akan memberikan transparansi untuk kontraktor, serta efisiensi pemasaran dan supply chain untuk para pemilik alat berat dan pemasok bahan konstruksi.</p>

                        <span class="h5 mb-4 text-white">Fokus kami adalah :</span>
                        <ul class="about-list2 my-4">
                            <li class="mb-3">Memenuhi segala kebutuhan kontraktor, menghubungkan kontraktor dan bisnis lain dengan pemilik alat berat dan pemasok bahan konstruksi se-Indonesia.
                            </li>

                            <li class="mb-3">
                               Memberikan transparansi dan kualitas layanan untuk para kontraktor.
                            </li>

                            <li class="mb-3">
                                Memudahkan mitra untuk mengatur aset, menurunkan biaya operasional dan meningkatkan efisiensi supply chain.
                            </li>
                        </ul>                        
                    </div>

                    <div class="col-lg-5 col-md-4">
                        <img src="@/assets/images/display4.jpg" alt="intro-img" class="img-fluid" style="height:500px; width:100%; object-fit: cover; object-position:center;">
                        
                    </div>
                </div>
            </div>
        </section>
        <!--  ABOUT AREA END  -->
        
        <!--  INTRO AREA START  -->
        <section id="contactus" class="section-padding text-dark mb-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-sm-12 col-md-12">
                    <h1 class="text-left text-dark">{{ $t('contact.introduction') }}</h1>
                    <h5 class="subtitle text-left text-dark">Tim customer service kami siap mendengarkan anda.</h5>
                
                    <h3 class="subtitle text-left text-dark mt-4">Kantor Utama</h3>
                    <p class="text-left text-dark mt-3" style="font-size:18px;">Jl. Darmo Permai Utara XI / 27, Surabaya,<br>Jawa Timur, Indonesia, 60226</p>
                    <p class="text-left" style="font-size:18px;">Phone: 0813-3553-5654<br>Email: <a href="mailto:support@fourcons.com">support@fourcons.com</a></p>

                    <h3 class="subtitle text-left text-dark mt-4">Sosial Media</h3>                                    
                    <a href="https://www.facebook.com/FourCons/" target="_blank"><img src="@/assets/images/facebook.png" alt="intro-img" class="img-fluid" style="width:40px"></a>
                    <a href="https://linkedin.com/company/fourconsid/" target="_blank"><img src="@/assets/images/linkedin.png" alt="intro-img" class="img-fluid" style="width:40px; margin:10px"></a>
                    <a href="https://www.instagram.com/fourcons.id/" target="_blank"><img src="@/assets/images/instagram.png" alt="intro-img" class="img-fluid" style="width:40px"></a>                  

                  </div>

                  <div class="col-lg-6 col-sm-12 col-md-12 mt-3">
                      <h3 class="subtitle text-left text-dark">Langsung Hubungi!</h3>
                      <el-form ref="form" :model="form" :rules="rules" label-width="100px" label-position="top">
                        <div class="row mt-3">
                          <el-alert
                            v-if="status"
                            :title="message"
                            :type="type"
                            class="col-md-12">
                          </el-alert>
                          <el-form-item class="col-md-6" prop="person_name" size="medium">
                            <el-input v-model="form.person_name" placeholder="Nama"></el-input>
                          </el-form-item>
                          <el-form-item class="col-md-6" prop="company_name">
                            <el-input v-model="form.company_name" placeholder="Nama perusahaan"></el-input>
                          </el-form-item>
                          <el-form-item class="col-md-6" prop="telephone">
                            <el-input v-model="form.telephone" placeholder="Telepon"></el-input>
                          </el-form-item>
                          <el-form-item class="col-md-6" prop="email">
                            <el-input v-model="form.email" placeholder="Email"></el-input>
                          </el-form-item>
                          <el-form-item class="col-md-12" prop="message">
                            <el-input type="textarea" rows="6" v-model="form.message" placeholder="Tuliskan pesan anda."></el-input>
                          </el-form-item>
                          <el-form-item class="col-md-12">
                            <el-button style="font-weight:900" type="primary" @click="submitForm">Kirim</el-button>
                          </el-form-item>
                        </div>
                      </el-form>  
                  </div>
              </div>
            </div>            
        </section>
        <!--  INTRO AREA END  -->
    </div>
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import machine_categories_list from '@/assets/definitions/machine_categories_list'

export default {
  name: 'about',
  metaInfo() {
    return {
      title: 'Tentang kami',
      meta: [
        { vmid: 'description', name: 'description', content: 'Fourcons adalah perusahaan yang menyediakan one stop solution untuk konstruksi Indonesia. Fourcons menghubungkan kontraktor dengan supplier alat berat maupun material. Fokus Fourcons adalah untuk Memenuhi segala kebutuhan kontraktor, menghubungkan kontraktor dan bisnis lain dengan pemilik alat berat dan pemasok bahan konstruksi seluruh Indonesia, memberikan transparansi dan kualitas layanan untuk para kontraktor, dan Memudahkan mitra untuk mengatur aset, menurunkan biaya operasional dan meningkatkan efisiensi supply chain.' },
        { vmid: 'keyword', name: 'keyword', content: 'visi fourcons, misi fourcons, konstruksi indonesia, integrasi bangsa' }
      ]
    }
  },
  components:{
  },
  data: function() {
    return {
      form: {
        person_name: '',
        company_name: '',
        telephone: '',
        email: '',
        message: ''
      },
      rules: {
        person_name: [
          { required: true, message: 'Silahkan masukkan nama anda', trigger: 'blur' },
        ],
        company_name: [
          { required: true, message: 'Silahkan masukkan nama perusahaan anda', trigger: 'blur' },
        ],
        telephone: [
          { required: true, message: 'Silahkan masukkan nomor telepon', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Silahkan masukkan email', trigger: 'blur' },
          { type: 'email', message: 'Silahkan masukkan email yang benar', trigger: ['blur', 'change'] }
        ],
        message: [
          { required: true, message: 'Silahkan masukkan pesan anda', trigger: 'blur' },
        ],
      },
      status: false,
      message: '',
      type: ''
    }
  },
  methods: {
    submitForm() {
      this.status = 0,
      this.message = '';
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = this.$loading();
          return axios.post('/support_ticket/create', this.form).then(response => {
            loading.close();
            this.status = true;
            this.message = 'Berhasil menghubungi Fourcons. Kami akan segera menghubungi Anda.';
            this.type = 'success';
            this.clearForm();
          }).catch(error => {
            loading.close();
            this.status = true;
            this.type = 'error';
            if (error.response.status == 429)
              this.message = 'Terlalu banyak mengirimkan pesan. Silahkan tunggu beberapa saat.';
            else
              this.message = 'Internal Server Error';
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    clearForm() {
      this.form.person_name = '';
      this.form.company_name = '';
      this.form.email = '';
      this.form.telephone = '';
      this.form.message = '';
    },
    clearAlert() {
      this.status = false;
      this.message = '';
      this.type = '';
    },
    goToContact() {
      this.$router.push({ name: 'contact' });
    }
  }
}
</script>
<style>
.vs--searchable .vs__dropdown-toggle {
    background-color:#fff;
}
input.vs__search {
    color: grey;
}
</style>