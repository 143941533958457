<template>
  <div class="container my-5">
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Confirm My Order ({{ orderData.machine_name }})</h1>
    </div>

    <!-- Content Row -->
    <div class="row">
      <div class="col-lg-12 card-header">
        <h4>Price Detail</h4>
      </div>

      <div class="col-lg-12 card-body">
        <div class="row">
          <div class="col-md-6 form-group col-sm-6">
            <h5>{{ $t('rent_detail.form_start_date') }}</h5>
            {{ orderData.start_date }}
          </div>              
          <div class="col-md-6 form-group col-sm-6">
            <h5>{{ $t('rent_detail.form_end_date') }}</h5>
            {{ orderData.end_date}}
          </div>                                
        </div>
        
        <h5>{{ $t('rent_detail.form_fee_detail') }}</h5>

        <div class="row mx-lg-2">
          <div class="col-lg-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="row">
                  <div class="col-lg-7 col-sm-7">
                    <h6>Rp {{ formatNumber(orderData.price_per_hour) }} x 8 {{ $t('rent_detail.hour') }} x {{ orderData.total_day }} {{ $t('rent_detail.day') }}</h6>
                  </div>
                  <div class="col-lg-5 col-sm-5 text-right">
                    <h6>Rp {{ formatNumber(orderData.total_rent_fee) }}</h6>
                  </div>
                </div>                      
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-lg-7 col-sm-7">
                    <h6>Transportation fee <br> Rp {{ formatNumber(orderData.transportation_fee) }} x 2</h6>
                  </div>
                  <div class="col-lg-5 col-sm-5 text-right">
                    <h6>Rp {{ formatNumber(orderData.total_transportation_fee) }}</h6>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-lg-7 col-sm-7">
                    <h6>Operator fee <br> Rp {{ formatNumber(orderData.operator_fee) }} x {{ orderData.total_day }} {{ $t('rent_detail.day') }}</h6>
                  </div>
                  <div class="col-lg-5 col-sm-5 text-right">
                    <h6>Rp {{ formatNumber(orderData.total_operator_fee) }}</h6>
                  </div>
                </div>                    
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-lg-7 col-sm-7">
                    <h6>Service fee</h6>
                  </div>
                  <div class="col-lg-5 col-sm-5 text-right">
                    <h6>Rp {{ formatNumber(orderData.service_fee) }}</h6>
                  </div>
                </div>                    
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-lg-5 col-sm-5">
                    <h4>Total</h4>
                  </div>
                  <div class="col-lg-7 col-sm-7 text-right">
                    <h4>Rp {{ formatNumber(orderData.grand_total) }}</h4>
                  </div>
                </div>                    
              </li>
            </ul>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-12 text-center mb-3">
            <button @click="submitOrder" type="button" class="btn btn-primary btn-circled btn-success btn-sm">Confirm</button>
            <button @click="cancelOrder" type="button" class="btn btn-default btn-circled btn-sm">Cancel</button>
          </div>
        </div>
      </div>                            
    </div>

    <div class="row">
      <div class="col-lg-12 card-header">
        <h4>Working Detail</h4>
      </div>
      
      <div class="col-lg-12 card-body">
        <div class="row">
          <div class="col-md-12 form-group col-sm-12">
            <h5>{{ $t('rent_detail.working_category') }}</h5>
            <input type="text" class="form-control" v-model="working_category" :placeholder="$t('rent_detail.working_category_placeholder')">
          </div>   

          <div class="col-md-12 form-group col-sm-12">
            <h5>{{ $t('rent_detail.working_description') }}</h5>
            <textarea class="form-control" rows="5" v-model="working_description" :placeholder="$t('rent_detail.working_description_placeholder')"></textarea>
          </div> 
        </div>

        <div class="row mt-3">
          <div class="col-lg-12 text-center mb-3">
            <button @click="submitOrder" type="button" class="btn btn-primary btn-circled btn-success btn-sm">Confirm</button>
            <button @click="cancelOrder" type="button" class="btn btn-default btn-circled btn-sm">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 card-header">
        <h4>Shipping Detail</h4>
      </div>
      
      <div class="col-lg-12 card-body">
        <div class="row">
          <div class="col-md-12 form-group col-sm-12">
            <h5>{{ $t('rent_detail.shipping_address') }}</h5>
            <input type="text" class="form-control" v-model="shipping_address" :placeholder="$t('rent_detail.shipping_address_placeholder')">
          </div>   

          <div class="col-md-12 form-group col-sm-12">
            <h5>{{ $t('rent_detail.shipping_receiver') }}</h5>
            <input type="text" class="form-control" v-model="receiver" :placeholder="$t('rent_detail.shipping_receiver_placeholder')">
          </div> 

          <div class="col-md-12 form-group col-sm-12">
            <h5>{{ $t('rent_detail.shipping_telephone') }}</h5>
            <input type="text" class="form-control" v-model="telephone" :placeholder="$t('rent_detail.shipping_telephone_placeholder')">
          </div> 
        </div>

        <div class="row mt-3">
          <div class="col-lg-12 text-center mb-3">
            <button @click="submitOrder" type="button" class="btn btn-primary btn-circled btn-success btn-sm">Confirm</button>
            <button @click="cancelOrder" type="button" class="btn btn-default btn-circled btn-sm">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../../axios.js';

export default {
  name: 'order.list',
  data: function() {
    return {
      shipping_address: ''
    }
  },
  computed: {
    orderData() {
      return !this.$store.getters.order ? false : this.$store.getters.order
    }
  },
  mounted: function() {
    if (!this.$store.state.order)
      this.$router.push({ name: 'rent' })
  },
  methods: {
    formatNumber(number) {
      if (!number) return 0;
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    submitOrder() {
      let data = {
        ...this.orderData,
        shipping_address: this.shipping_address
      }
      this.$store.dispatch('confirmOrder', data)
        .then(response => {}, error => {});
    },
    cancelOrder() {
      this.$router.go(-1)
    }
  }
}
</script>