<template>
  <div>
    <div class="banner-area banner-1" style="min-height:100vh">
      <div class="overlay dark-overlay"></div>
      <div class="container d-flex">
          <div class="row align-items-center" style="min-height:100vh">

              <div class="col-lg-5 m-auto text-center col-sm-12 col-md-12 d-none d-sm-none d-md-none d-lg-block">
                  <div class="banner-content col-md-12" style="margin-top:100px">
                    <img src="@/assets/images/display1.png" alt="intro-img" class="img-fluid" style="width:90%">
                  </div>
              </div>

              <div class="col-lg-6 m-auto text-center col-sm-12 col-md-12">
                  <div class="banner-content content-padding-2 col-md-12">
                    <!-- <h1 class="col-md-8 banner-title text-left">{{ $t('about.header.introduction') }}</h1> -->
                    <h1 class="banner-title text-left">Solusi<br>Konstruksi<br>Pembangunan</h1>
                    <h5 class="subtitle text-left">Perusahaan konstruksi yang menghubungkan kontraktor dengan pemasok alat dan material yang tersebar di Indonesia.</h5>
                    
                    <div class="text-left">
                      <a href="https://play.google.com/store/apps/details?id=fourcons.customer" target="_blank"><img src="@/assets/images/google-play-badge.png" alt="intro-img" class="img-fluid" style="width:30%"></a>
                      <a href="https://apps.apple.com/id/app/fourcons-contractor/id1499183722" target="_blank" style="margin:20px"><img src="@/assets/images/app-store-badge.png" alt="intro-img" class="img-fluid" style="width:28%"></a>
                    </div>

                    <div class="text-left mt-4">
                      <el-button type="primary" @click="goToOrder" style="font-weight:900">Pesan Sekarang</el-button> 
                    </div>                    
                  </div>
              </div>

          </div>
      </div>
    </div>

     <!--  INTRO AREA START  -->
        <section id="intro" class="section-padding">
          <div class="container">
              <div class="row">                
                  <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="col-lg-12 col-sm-12 col-md-12">                            
                          <!-- <h2 class=" section-title text-left">{{ $t('about.intro.introduction') }}</h2> -->
                          <h2 class=" section-title text-left">Fourcons menghubungkan kontraktor dengan para pemilik alat dan pemasok</h2>
                          <div class="line"></div>                            
                      </div>
                                              
                      <div class="col-lg-12 col-sm-12 col-md-12 text-dark">    
                          <p>Fokus kami adalah menyediakan solusi untuk segala keperluan proyek pembangunan anda, meningkatkan efisiensi, memberikan transparansi dan menurunkan biaya.</p>
                          <p>Kami menghubungkan kontraktor dengan pemilik alat berat, pemilik tambang dan pemasok bahan bangunan yang tersebar di seluruh Indonesia. Memberikan harga kompetitif dan kualitas yang terjamin.</p>
                      </div>                        
                      <div class="col-md-12 mt-4">
                        <a href="" @click="goToContact" class="mt-3 d-inline-block">Cari tahu lebih lanjut <i class="fa fa-angle-right"></i></a>
                      </div>
                  </div>

                  <div class="col-lg-1"></div>
                  <div class="col-lg-5 d-none d-lg-block col-sm-12">                      
                    <img src="@/assets/images/display2.jpg" alt="intro-img" class="img-fluid" style="height: 450px; width:90%; object-fit: cover; object-position:center;">
                  </div>
              </div>
          </div>
        </section>
        
        <!--  ABOUT AREA START -->
        <div class="bg-why-fourcons" style="min-height:100vh">
            <div class="container">

                <div class="row mb-5">
                    <div class="col-lg-12 col-sm-12 col-md-12 bg-white">
                        <h1 class="text-center">{{ $t('about.about.introduction') }}</h1>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-lg-4 col-sm-12 col-md-8 px-5">
                      <div class="row mb-2">
                        <img src="@/assets/images/exchange-rate.png" alt="intro-img" class="img-fluid mr-1" style="width:40px; height:40px">
                        <h4>Rating Harga Kompetitif</h4>
                      </div>
                        <p style="text-align:left; padding-left:40px">Variasi penawaran harga yang kompetitif dari vendor-vendor terpercaya.</p>
                    </div>                    

                    <div class="col-lg-4 col-sm-12 col-md-12">
                    </div>

                    <div class="col-lg-4 col-sm-12 col-md-8 px-5">
                      <div class="row mb-2">
                        <img src="@/assets/images/support.png" alt="intro-img" class="img-fluid mr-1" style="width:40px; height:40px">
                        <h4>Mitra Terpercaya</h4>
                      </div>
                        <p style="text-align:left; padding-left:40px">Vendor yang terverifikasi dengan kualitas dan kepercayaan yang terjamin.</p>
                    </div>
                </div>

                <div class="d-none d-sm-none d-md-none d-lg-block" style="margin-top:60px">
                </div>

                <div class="row">               
                    <div class="col-lg-4 col-sm-12 col-md-8 px-5">
                      <div class="row mb-2">
                        <img src="@/assets/images/network.png" alt="intro-img" class="img-fluid mr-1" style="width:40px; height:40px">
                        <h4>Vendor yang Tersebar</h4>
                      </div>
                        <p style="text-align:justify; padding-left:40px">Ratusan mitra vendor tersebar di seluruh daerah Indonesia.</p>
                    </div>

                    <div class="col-lg-4 col-sm-12 col-md-12">
                    </div>

                    <div class="col-lg-4 col-sm-12 col-md-8 px-5">
                      <div class="row mb-2">
                        <img src="@/assets/images/choices.png" alt="intro-img" class="img-fluid mr-1" style="width:40px; height:40px">
                        <h4>Pembayaran Fleksibel</h4>
                      </div>
                        <p style="text-align:justify; padding-left:40px">Pilihan waktu pembayaran sesuai dengan keinginan anda.</p>
                    </div>
                </div>

            </div>            
        </div>
        <!--  ABOUT AREA END -->

        <!--  SERVICE BLOCK2 START  -->
        <section id="service-2" class="section-padding bg-dark-blue">
            <div class="container">
              <h1 class="text-center text-white mb-4"> Cara Memesan </h1>
                <div class="row justify-content-center text-white">
                    <div class="col-lg-3 col-md-6">
                        
                        <div class="service-box-2">                            
                            <span>01</span>                          
                            <img src="@/assets/images/tap-2.png" alt="intro-img" class="ml-5 mb-3 img-fluid d-none d-sm-none d-md-none d-lg-block" style="width:80px; height:80px;filter: brightness(0) invert(1);">
                            <h4 class="text-white">Pilih Alat<br>dan Material</h4>
                            <p class="mt-3">Pilih alat, spesifikasi dan jumlah yang diperlukan. Sertakan catatan bila diperlukan tahun, merek, atau keperluan lainnya.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="service-box-2">
                            <span>02</span>
                            <img src="@/assets/images/clipboard.png" alt="intro-img" class="ml-5 mb-3 img-fluid d-none d-sm-none d-md-none d-lg-block" style="width:80px; height:80px;filter: brightness(0) invert(1);">
                            <h4 class="text-white">Lengkapi <br>Detil pesanan</h4>
                            <p class="mt-3">Lengkapi informasi lokasi proyek, tanggal, dan detil pekerjaan yang dikerjakan alatmu.</p>                            
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="service-box-2">
                            <span>03</span>
                            <img src="@/assets/images/file.png" alt="intro-img" class="ml-5 mb-3 img-fluid d-none d-sm-none d-md-none d-lg-block" style="width:80px; height:80px;filter: brightness(0) invert(1);">
                            <h4 class="text-white">Dapatkan <br>Penawaran Harga</h4>
                            <p class="mt-3">Dalam waktu beberapa jam, para vendor akan memberikan penawaran harga dan detil penawaran barang.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="service-box-2">
                            <span>04</span>
                            <img src="@/assets/images/contract.png" alt="intro-img" class="ml-5 mb-3 img-fluid d-none d-sm-none d-md-none d-lg-block" style="width:80px; height:80px;filter: brightness(0) invert(1);">
                            <h4 class="text-white">Kerja sama<br>dengan Vendor</h4>
                            <p class="mt-3">Setujui penawaran vendor. Alat dan material pesanan anda akan tiba di lokasi dan waktu yang anda tentukan.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--  SERVICE BLOCK2 END  -->
        
        <section id="order" class="section-padding">
          <div class="container">
              <div class="row">
                  <div class="col-md-12">
                    <h1 class="section-title text-center text-dark"> Berbagai Alat Berat Tersedia Untuk Anda </h1>
                    <h4 class="text-center text-dark"> Pilih alatmu sekarang </h4>
                    <div class="line" style="margin-bottom:30px"></div>                     
                    <el-tabs v-model="active_category" @tab-click="handleClick" :stretch="true">
                      <el-tab-pane :label="category.name" :name="category.id.toString()" :key="index" v-for="(category, index) in categories">
                        <div class="row">         
                          <!-- Machine Category -->
                          <div class="card-body col-md-2" style="cursor:pointer;" :key="index" @click="redirectDetail(subcategory.id)" v-for="(subcategory, index) in category.subcategories">
                            <div class="row">
                              <div class="col-md-12 d-flex">
                                <img style="height: 120px; width:100%; object-fit: contain; object-position:center;" :src="subcategory.photo" />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12 text-center">
                                <div class="d-flex align-items-center">
                                  <h6 class="card-title mb-0" style="flex-grow: 1;">{{ subcategory.name }}</h6>                    
                                </div>
                                <div class="d-flex align-items-center">
                                    <p class="m-0 text-dark" style="flex-grow: 1;">Sewa sekarang</p>                            
                                </div>
                              </div>
                            </div>
                          </div>  
                          <!-- End of Machine Category-->
                        </div>
                      </el-tab-pane>
                    </el-tabs>    
                    <div class="line" style="margin-bottom:30px"></div>                     
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <h1 class="section-title text-center text-dark"> Cari alat berat dan material lain? </h1>
                        <h4 class="text-center text-dark"> Dapatkan konsultasi dan penawaran langsung </h4>                
                        <div class="col-md-12 mt-4">
                        <el-button type="primary" @click="goToContact" style="font-weight:900">Dapatkan Penawaran</el-button> 
                      </div>
                  </div>                  

              </div>         
                  </div>                  
              </div>
          </div>
        </section>
  </div>
</template>
<script>
import axios from 'axios';
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import machine_categories_list from '@/assets/definitions/machine_categories_list'

const baseURL = process.env.VUE_APP_API_URL || 'http://localhost:3000' 

export default {
  name: 'rent',
  metaInfo() {
    return {
      title: 'Sewa Alat Berat dan Beli Material',
      meta: [
        { vmid: 'description', name: 'description', content: 'Fourcons menghubungkan kontraktor dengan rental alat berat dan supplier material yang tersebar di seluruh Indonesia. Sewa alat berat, sewa excavator, sewa bulldozer, sewa crane, sewa forklift, sewa dump truck, sewa alat berat lainnya? Sewa di Fourcons saja. Beli material konstruksi, beli pasir, beli batu, beli baja, beli PVC, beli semen, beli kayu, beli material konstruksi lainnya? Beli di fourcons saja' },
        { vmid: 'keyword', name: 'keyword', content: 'sewa alat berat, beli material, rental alat berat, supplier material, kontraktor, konstruksi, pertambangan, indonesia, sewa excavator, sewa bulldozer, sewa crane, beli pasir, beli kayu, beli baja, beli material konstruksi' }
      ]
    }
  },
  components:{
    vSelect,
    Datepicker
  },
  data: function() {
    return {
      active_category: '',
      disabledDate: {
        to: new Date(),
      },
      categories: [],
      data: {
        payload: [{
          photos: [],
          price_per_hour: 0
        }]
      },
    }
  },
  mounted() {
    this.fetchCategories()
  },
  methods: {
    handleClick(tab, event) {
      
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    fetchCategories() {
      const loading = this.$loading();
      axios.get(baseURL+'/category/all')
        .then((response) => {
          loading.close();
          this.categories = response.data.payload
          this.active_category = this.categories[0].id.toString()
        })
        .catch((error) => {
          loading.close();
          console.log('fetch machine list error', error)
        })
    },
    redirectDetail(id) {
      this.$router.push({ name: 'rent.detail', params: { id }})
    },
    goToContact() {
      this.$router.push({ name: 'contact' });
    },
    goToLogin() {
      this.$router.push({ name: 'login' });
    },
    goToOrder() {
      const element = document.getElementById('order');
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>