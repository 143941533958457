export default {
  'layout': {
    'rent_out': 'Rent out your heavy equipment',
    'contact': 'Help',
    'about': 'About us',
    'footer': '© 2019 Fourcons. All Rights Reserved.',
    'cart': 'Cart',
    'order': 'Dashboard',
    'login': 'Login',
    'logout': 'Logout'
  },
  'about': {
    'header': {
      'introduction': 'Rent heavy equipments in a second at ease',
      'alert_success': 'Thank you, we are procesing your quotation now. We will contact you within 24 hours.',
      'alert_incomplete': 'Please fill the form completely.',
      'alert_email_malformed': 'Please fill your email correctly.',
      'form_location': 'Project location',
      'form_location_placeholder': 'Type a city nearby your project',
      'form_type': 'Heavy equipment type',
      'form_type_placeholder': 'Choose your equipment',
      'form_start_date': 'Starting date',
      'form_start_date_placeholder': 'Choose your rent out day',
      'form_end_date': 'End date',
      'form_end_date_placeholder': 'Choose your last rent out day',
      'form_email': 'Email',
      'form_email_placeholder': 'example@mail.com',
      'form_submit': 'Get your best offer'
    },
    'intro': {
      'introduction': 'Are you looking for a trustable heavy equipment rental?',
      'problem_1_1': 'A thorough, complicated and verification process is needed to rent a heavy equipment.',
      'problem_1_2': 'Which is consuming monetarily and in timely manner.',
      'solution_1_1': 'The good news is, this troble some process could be easy and cheap!',
      'solution_1_2': 'We simply can help you in:',
      'solution_list_1': 'Find your best-fit heavy equipment',
      'solution_list_2': 'Aided financially',
      'solution_list_3': 'Aided during the renting duration',
      'action': 'GET YOUR FREE CONSULTATION'

    },
    'about': {
      'introduction': 'Why Fourcons?',
      'about_1': 'Save time and easy',
      'about_1_description': '<b>Find, compare and decide</b>, as easy as that. Fasten the complicated process and focus on your project.',
      'about_2': 'Price and quality transparency',
      'about_2_description': 'You can <b>see and evaluate all cost and quality easily</b>. So that, you can consider and decide your right equipment fittingly.',
      'about_3': 'Installment after project',
      'about_3_description': 'With fourcons, you can <b>pay the cost gradually even after your equipment project is over</b>. We smoothen your cashflow and your overall project.',
    },
    'benefit': {
      'introduction': 'With FOURCONS, you will get the benefits of:',
      'description_1': '<b>Heavy equipment quality and security assured</b> is our main priority.',
      'description_2': 'Our partner have been through our high standard of selection and evalution.',
      'description_3': 'With our system, monitor and oversee your progress and the equipment productivity in <b>real time</b>.',
      'system_1': 'Check transaction status.',
      'system_2': 'Keep your eye on delivery status.',
      'system_3': 'Monitor your heavy equipment activity.',
      'finance_header': 'Gradual payment with personalize installment scenario:',
      'finance_1': 'at the beginning.',
      'finance_2': 'at the end date.',
      'finance_3': 'afterwards.'
    },
    'contact': {
      'introduction': 'Eager to know more?',
      'description': 'We would like to know what do you need for your heavy equipments; and help you out.',
      'action': 'GET HELP'
    },
    'support': {
      'introduction': 'SUPPORTED BY'
    }
  },
  'rent_out': {
    'header': {
      'alert_success': 'Your data have been saved, we will contact you soon.',
      'alert_incomplete': 'Please fill the form completely.',
      'alert_email_malformed': 'Please fill your email correctly.',
      'introduction': 'Get extra money by renting out your heavy equipment in FOURCONS',
      'form_telephone': 'Phone number',
      'form_telephone_placeholder': 'Your phone number',
      'form_email': 'Email',
      'form_email_placeholder': 'example@mail.com',
      'form_submit': 'JOIN TO LEND'
    },
    'intro': {
      'why_1': 'Why join us?',
      'why_1_description': "Heavy equipment requires heavy investment, not to mention it's maintenance depreciation and when it sits idle, it doesnt contribute to your bottom line. Lending equipment is your sole answer. Whatever your heavy equipment is and how old it is, don't put it in your garage.",
      'why_2': "Don't worry, it's a win-win solution",
      'why_2_description': 'To secure you from any damage, we evaluate our customers. Trustworthy and security is our priorities. We are ready to help you anytime.'
    },
    'step': {
      'introduction': 'RENT OUT STEPS',
      'step_1': 'List your heavy equipment for free',
      'step_1_description': 'Share your heavy equipment in our platform for free, get your exposure and rent out your excavator, wheelloader, bulldozer, and others to get more income.',
      'step_2': 'Decide how will you rent out',
      'step_2_description': 'Schedule and set your heavy equipment price easily.',
      'step_3': 'Rent out your heavy equipment',
      'step_3_description': 'After your heavy equipment are listed, you will get order rightaway from our verified customer.',
      'step_3_action': 'Get more information for rent out'
    },
    'about': {
      'introduction': 'EASY PAYMENT',
      'why_1': 'Decide your own price according to your wishes.',
      'why_1_description': 'You can decide your own heavy equipment price with the other costs that you need.',
      'why_2': 'Get your payment fast',
      'why_2_description': 'We will transfer your money right away to your bank account when both side have agree to the lease contract.'
    }
  },
  'contact': {
    'introduction': 'CONTACT US',
    'description_1': 'Fill this form out, or simply let us know via: ',
    'description_2': 'Rent heavy equipment at ease. Contact us for your personalized needs.',
    'alert_success': 'Data have been saved successfully, we will contact you soon.',
    'alert_incomplete': 'Please fill the form completely.',
    'alert_email_malformed': 'Please fill the email correctly.',
    'form_contact_name': 'Name',
    'form_contact_name_placeholder': 'Your name',
    'form_company_name': 'Company name',
    'form_company_name_placeholder': 'Your compant name',
    'form_telephone': 'Phone or Whatsapp',
    'form_telephone_placeholder': 'Fill your phone number',
    'form_email': 'Email',
    'form_email_placeholder': 'example@mail.com',
    'form_message': 'What can we help?',
    'form_message_placeholder': 'Write your enquiry.',
    'form_submit': 'Send',
  },
  'rent': {
    'form_location': 'Project location',
    'form_location_placeholder': 'Type a city nearby your project',
    'form_category': 'Heavy equipment type',
    'form_category_placeholder': 'Choose your equipment',
    'form_start_date': 'Starting date',
    'form_start_date_placeholder': 'Choose your date',
    'form_end_date': 'End date',
    'form_end_date_placeholder': 'Choose your date',
    'search': 'SEARCH',    
  },
  'rent_detail': {
    'specification_title': 'Specification',
    'specification_brand': 'Brand / Made in',
    'specification_type': 'Type',
    'specification_year': 'Made in year',
    'specification_SIA': 'SIA (Tool Licency)',
    'specification_SILO': 'SILO (Proper Operation Permit)',
    'specification_description': 'Description',
    'form_start_date': 'Starting date',
    'form_start_date_placeholder': 'Choose your date',
    'form_end_date': 'End date',
    'form_end_date_placeholder': 'Choose your date',
    'form_fee_detail': 'Fee and tax details',
    'button_quote' : 'GET QUOTATION',
    'hour' : 'hour',
    'day' : 'day',
    'shipping_address': 'Address',
    'shipping_address_placeholder': 'Please input your shipping address',
    'shipping_receiver': 'Receiver',
    'shipping_receiver_placeholder': 'Please input your shipping receiver',
    'shipping_telephone': 'Telephone',
    'shipping_telephone_placeholder': 'Please input your shipping telephone',
    'work_scope': 'Project field',
    'work_scope_placeholder': 'Ex: construction, mining, forestry, dll',
    'work_description': 'Heavy equipment technical work',
    'work_description_placeholder': 'Ex: gali tanah, dll'
  }
}