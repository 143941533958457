import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '../../assets/client/language/en-US';
import id from '../../assets/client/language/id-ID';

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'id-ID',
  messages: {
    'id-ID': id,
    'en-US': en,
  }
})

export default i18n;