<template>
    <div class="container my-3">
      <div class="row mt-3 mx-3">
      
        <div class="col-lg-7">
          <div class="row">
            <div class="col-lg-12 text-dark">
              <h3> {{ data.name }} </h3>
            </div>

            <div class="col-lg-12 text-dark mt-3">
              <div class="row">
                <div class="col-lg-12">
                  <img @click="openPhotoDetail" style="object-fit: contain; cursor:pointer; object-position:center center; max-width:100%; height:400px; width: 100%" :src="data.photo" />    
                </div>

                <el-dialog :visible.sync="dialogPhotoVisible" fullscreen>
                  <img style="object-fit: contain; object-position:center center; max-width:100%; height:85vh; width: 100%" :src="dialogPhoto" />
                </el-dialog>
              </div>  
              <h5> {{ $t('rent_detail.specification_description') }} </h5>
                <p class="mx-md-3"> {{ data.description }}</p>
            </div>
          </div>
        </div>


        <div class="col-lg-5 card">
          <div class="row">
            <div class="col-lg-12 card-body">
              <h5 class="my-2">Detail Pesanan</h5>
              <el-form ref="form" :model="form" label-width="120px">
                <el-form-item :label="data.specification_name" prop="specification">
                  <el-select v-model="form.specification" filterable>
                    <el-option
                      v-for="(specification, index) in data.specifications"
                      :key="index"
                      :label="specification"
                      :value="specification">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Jumlah">
                  <el-input-number v-model="form.count" :min="1"></el-input-number>
                </el-form-item>
                <el-form-item label="Notes">
                  <el-input type="textarea" :autosize="{ minRows: 4 }" v-model="form.notes" placeholder="Tidak wajib. Isi catatan tambahan seperti minimum tahun pembuatan, merk yang diinginkan, dll."></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="addToCart">Add to cart</el-button>
                </el-form-item>
              </el-form>
            </div>                            
          </div>
        </div>

      </div>                        
    </div>
</template>
<script>
import axios from 'axios';
import Datepicker from 'vuejs-datepicker';

const baseURL = process.env.VUE_APP_API_URL || 'http://localhost:3000' 

export default {
  name: 'rent.detail',
  metaInfo() {
    return {
      title: 'Sewa Alat Berat dan Beli Material',
      meta: [
        { vmid: 'description', name: 'description', content: this.data.description },
        { vmid: 'keyword', name: 'keyword', content: 'sewa '+this.data.name+' indonesia, rental '+this.data.name+' indonesia' }
      ]
    }
  },
  components:{
    Datepicker
  },
  data: function() {
    return {
      data: {
        subcategory_id: '',
        count: '',
        description: '',
        name: ''
      },
      form: {
        subcategory_id: this.$route.params.id,
        count: '',
        specification: '',
        notes: ''
      },
      dialogPhotoVisible: false,
      dialogPhoto: null
    }
  },
  mounted: function() {
    this.fetchSubcategoryDetail();
  },
  methods: {
    openPhotoDetail() {
      this.dialogPhotoVisible = true;
      this.dialogPhoto = this.data.photo
    },
    fetchSubcategoryDetail() {
      axios.get(baseURL+'/subcategory/'+this.$route.params.id)
        .then((response) => {
          this.data = response.data.payload;
        })
        .catch((error) => {
          console.log("fetch machine detail error", error);
        })
    },
    addToCart() {
      const input = {
        photo: this.data.photo,
        name: this.data.name,
        subcategory_id: this.form.subcategory_id,
        specification: this.form.specification,
        count: this.form.count,
        notes: this.form.notes
      }
      this.$store.dispatch('addToCart', input)
        .then(response => {}, error => {});
    }
  }
}
</script>