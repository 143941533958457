<template>
  <div class="container mt-4">
    <div class="row">

      <div class="col-md-12 my-2">
        <h1>Kebijakan Privasi</h1>
      </div>

      <div class="col-md-12 my-2">
        Kebijakan Privasi berikut ini menjelaskan bagaimana Kami mengumpulkan, menggunakan, memproses, mengungkapkan dan melindungi informasi pribadi Anda yang dapat diidentifikasi yang diperoleh melalui Situs Web dan Aplikasi Kami sebagaimana yang ditentukan dalam definisi di bawah. Mohon untuk dibaca Kebijakan Privasi ini dengan teliti untuk memastikan bahwa Anda memahami bagaimana Kami memproses dan mengelola data Anda. Kecuali ditentukan sebaliknya, semua istilah dengan huruf kapital yang digunakan dalam Kebijakan Privasi ini memiliki arti yang sama dengan yang tercantum dalam Ketentuan Penggunaan.
      </div>

      <div class="col-md-12 my-2">
        Kebijakan Privasi ini disusun sebagai bagian dari ketentuan penggunaan aplikasi, situs web, produk-produk, fitur-fitur, dan Layanan lainnya. Kebijakan Privasi ini mencakup hal-hal sebagai berikut:
        <ol class="mb-0">
          <li class="list-style-type-upper-alpha">Definisi</li>
          <li class="list-style-type-upper-alpha">Informasi dikumpulkan</li>
          <li class="list-style-type-upper-alpha">Penggunaan informasi dikumpulkan</li>
          <li class="list-style-type-upper-alpha">Pemberian informasi dikumpulkan</li>
          <li class="list-style-type-upper-alpha">Penahanan informasi dikumpulkan</li>
          <li class="list-style-type-upper-alpha">Keamanan</li>
          <li class="list-style-type-upper-alpha">Perubahan atas Kebijakan Privasi ini</li>
          <li class="list-style-type-upper-alpha">Lain-lain</li>
          <li class="list-style-type-upper-alpha">Pengakuan dan persetujuan</li>
          <li class="list-style-type-upper-alpha">Cara untuk menghubungi Kami</li>
        </ol>
      </div>

      <div class="col-md-12 my-2">
        Penggunaan Aplikasi dan Situs Web Kami tunduk pada Ketentuan Kebijakan Privasi ini dan dengan adanya Kebijakan Privasi ini, maka Anda dianggap telah memberikan persetujuan terhadap Kebijakan Privasi.
      </div>

      <div class="col-md-12 my-2">
        <ol style="padding-inline-start:1.25rem">
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            <span>Definisi</span>
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Kami"</span> berarti PT Pembangunan Integrasi Bangsa (<span class="font-weight-bold">"Fourcons"</span>), suatu perseroan yang bergerak di bidang konstruksi dan didirikan berdasarkan hukum Negara Republik Indonesia.</li>
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Aplikasi"</span> berarti suatu perangkat lunak yang telah Kami kembangkan sebagai hak paten dalam bentuk apapun, hak rancangan, dan/atau hak penemuan yang serupa lainnya termasuk tetapi tidak terbatas pada hak cipta, rahasia dagang, merek dagang, merek jasa dan hak properti Kami sebagai suatu sarana untuk menemukan Layanan yang disediakan oleh Penyedia Layanan.</li>
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Penyedia Layanan"</span> berarti suatu pihak ketiga sebagai mitra kerja Kami termasuk tetapi tidak terbatas pada agen, vendor, pemasok, kontraktor, sub-kontraktor, dan pihak lainnya yang memberikan Layanan kepada Kami, melakukan tugas dan/atau mewakili untuk dan atas nama Kami sehubungan dengan penyediaan Layanan melalui Situs Web dan Aplikasi.</li>
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Layanan"</span> berarti Layanan konstruksi dengan menggunakan armada yang ditawarkan oleh Penyedia Layanan melalui Situs Web dan Aplikasi yang dapat mencakup Layanan sewa alat berat, pembelian material, dan/atau Layanan lainnya yang berkaitan dengan Layanan konstruksi.</li>
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Informasi Pribadi"</span> berarti informasi mengenai Anda yang secara pribadi dikumpulkan melalui Situs Web dan/atau Aplikasi termasuk tetapi tidak terbatas pada nama, alamat, tanggal lahir, pekerjaan, nomor telepon, rekening bank, alamat surat elektronik (e-mail) Anda dan/atau informasi lain yang mungkin dapat mengidentifikasi orang tertentu yang sedang menggunakan Situs Web atau Aplikasi.</li>
              <li class="list-style-type-decimal"><span class="font-weight-bold">"Situs Web"</span> berarti Situs Web Kami dengan domain fourcons.com.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Informasi dikumpulkan
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Informasi yang Anda berikan secara langsung.</li>
              <li class="list-style-type-decimal">Kami mengumpulkan Informasi Pribadi dan/atau perusahaan tertentu Anda agar Aplikasi dapat menemukan Layanan dari Penyedia Layanan. Sebagai contoh, saat Anda mendaftar pada Situs Web dan/atau Aplikasi, Anda akan memberikan kepada Kami alamat surat elektronik/surel (e-mail), nama, nomor telepon, dan sandi akun Anda, sehingga beberapa informasi tersebut akan secara otomatis dikumpulkan ketika Anda mendaftar dan/atau menggunakan Aplikasi.</li>
              <li class="list-style-type-decimal">Informasi Anda berikan melalui Penyedia Layanan yang hanya sebatas pada pemenuhan tujuan kerja sama Kami dengan Penyedia Layanan.</li>
              <li class="list-style-type-decimal">Ketika Anda mengunjungi Situs Web Kami, administrator Situs Web Kami akan memproses data teknis seperti alamat IP Anda, halaman Situs Web yang pernah sebelum atau sesudahnya Anda kunjungi, browser internet yang Anda gunakan, dan durasi setiap kunjungan/sesi yang memungkinkan Kami untuk mengirimkan fungsi-fungsi Situs Web.</li>
              <li class="list-style-type-decimal">Ketika Anda menggunakan Situs Web dan/atau Aplikasi untuk menemukan suatu Layanan, Anda memberikan informasi pada Kami, yaitu lokasi proyek anda dan lama waktu sewa. Anda juga memberikan informasi kepada Kami mengenai alat berat yang anda sewakan dan  lokasi absensi operator ketika Anda menggunakan Layanan Kami.</li>
              <li class="list-style-type-decimal">Kami akan mengumpulkan dan menyimpan data spesifikasi kendaraan Anda termasuk tetapi tidak terbatas pada nomor polisi, jenis alat, model alat, kapasitas alat. serta merek alat sehubungan dengan pemberian Layanan kepada Anda.</li>
              <li class="list-style-type-decimal">Kami akan meminta nomor telepon dan/atau alamat Anda agar dapat dihubungi oleh Penyedia Layanan untuk memproses pesanan Anda ketika Anda menggunakan Situs Web atau Aplikasi untuk menemukan Layanan Kami.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Penggunaan informasi dikumpulkan
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Kami menggunakan informasi pribadi dalam bentuk tanpa nama dan secara keseluruhan untuk memantau lebih dekat fitur-fitur mana dari Layanan yang paling sering digunakan, untuk menganalisa pola penggunaan dan untuk menentukan apakah Kami akan menawarkan atau fokus pada Layanan Kami. Anda dengan ini setuju bahwa data Anda akan digunakan oleh pemrosesan data internal Kami untuk memberikan Layanan yang lebih baik kepada Anda.</li>
              <li class="list-style-type-decimal">Kami menggunakan surel, nama, nomor telepon, dan sandi akun Anda untuk memverifikasi kepemilikan Anda atas suatu akun, untuk berkomunikasi dengan Anda sehubungan dengan pesanan Anda dan untuk memberikan Anda informasi mengenai Situs Web dan Aplikasi.</li>
              <li class="list-style-type-decimal">Kami menggunakan informasi Anda untuk mengirimkan pesan, pembaharuan yang bersifat umum atas Situs Web dan Aplikasi, penawaran-penawaran khusus atau promosi-promosi sebagai bentuk berlangganan terhadap Situs Web dan Aplikasi Kami. Anda dapat setiap saat memilih untuk tidak menerima informasi mengenai pembaharuan ini.</li>
              <li class="list-style-type-decimal">Ketika Anda menggunakan Situs Web atau Aplikasi Kami, Kami juga akan memproses data teknis Anda seperti alamat IP, Identitas (ID) Perangkat, lokasi GPS perangkat, dan informasi mengenai pabrikan, model, dan sistem operasi dari perangkat bergerak Anda termasuk tetapi tidak terbatas pada surat jalan, foto SILO Alat Berat, foto lokasi muat dan bongkar serta foto operator dan foto SIO Operator. Kami menggunakan Informasi Anda untuk mengirimkan fungsi-fungsi dari Situs Web dan Aplikasi, mengupdate status pengiriman barang yang dikirim dan/atau dibeli, menyelesaikan kesulitan-kesulitan teknis, menyediakan versi Aplikasi yang benar dan terkini dan untuk meningkatkan fungsi Aplikasi kepada Anda.</li>
              <li class="list-style-type-decimal">Kami menggunakan informasi seperti alat-alat yang Anda sewakan untuk menentukan apakah Situs Web dan Aplikasi dapat menerima pesanan Anda berdasarkan ketentuan penggunaan.</li>
              <li class="list-style-type-decimal">Kami menggunakan informasi Anda untuk mengelola dan memfasilitasi pesanan dan transaksi pembayaran yang Anda lakukan dan berkomunikasi dengan Anda untuk mengirimkan informasi sehubungan dengan penggunaan Aplikasi.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Pemberian informasi dikumpulkan
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Setelah menerima pesanan Anda, Kami akan memberikan informasi seperti nama, nomor telepon, lokasi, alat yang akan dikirimkan/diantar untuk Anda kepada Penyedia Layanan yang menerima permintaan Anda atas Layanan. Informasi ini dibutuhkan oleh Penyedia Layanan untuk menghubungi Anda, dan/atau menemukan Anda dan/atau memenuhi pesanan Anda.</li>
              <li class="list-style-type-decimal">Kami tidak memberikan informasi pribadi Anda kepada pihak manapun selain kepada Penyedia Layanan, tanpa persetujuan dari Anda. Namun demikian, Kami akan mengungkapkan informasi pribadi Anda sepanjang disyaratkan oleh perintah pengadilan dan/atau peraturan perundang-undangan yang berlaku termasuk tetapi tidak terbatas pada menanggapi pertanyaan terkait regulasi, mempermudah proses penyelidikan dan/atau mematuhi persyaratan terkait pengarsipan dan pelaporan yang ditentukan oleh peraturan perundang-undangan yang berlaku.</li>
              <li class="list-style-type-decimal">Anda dengan ini setuju dan memberikan wewenang pada Kami untuk memberikan Informasi Pribadi Anda kepada Penyedia Layanan sebagai suatu bagian dari ketentuan Layanan. Walaupun informasi pribadi Anda secara otomatis akan dihapus dari perangkat bergerak milik Penyedia Layanan setelah Anda menggunakan Layanan, terdapat kemungkinan dimana Penyedia Layanan dapat menyimpan data Anda di perangkat mereka dengan cara apapun. Kami tidak bertanggung jawab atas penyimpanan data dengan cara tersebut dan Anda setuju untuk membela, memberikan ganti rugi dan membebaskan Kami dan Kami tidak akan bertanggung jawab atas segala penyalahgunaan informasi pribadi Anda oleh Penyedia Layanan setelah berakhirnya Layanan yang diberikan.</li>
              <li class="list-style-type-decimal">Informasi pribadi Anda dapat dialihkan, disimpan, digunakan, dan diproses di suatu yurisdiksi selain Indonesia dimana server-server Kami berada. Anda memahami dan setuju atas pengalihan informasi pribadi Anda ke luar Indonesia.</li>
              <li class="list-style-type-decimal">Dalam hal adanya penggabungan, penjualan aset perusahaan, konsolidasi atau restrukturisasi, pembiayaan atau akuisisi baik sebagian atau seluruh bisnis Kami oleh atau terhadap perusahaan lain, untuk keperluan transaksi tersebut (terlepas dari transaksi tersebut berlanjut atau tidak).</li>
              <li class="list-style-type-decimal">Kami juga akan memberikan Informasi Anda kepada Penyedia Layanan sehubungan dengan pemenuhan tujuan kerja sama Kami dengan Penyedia Layanan dimana dapat mencakup diperbolehkannya Penyedia Layanan untuk memperkenalkan atau menawarkan Layanan kepada Anda dan/atau melakukan kegiatan lain termasuk tetapi tidak terbatas pada pemasaran, penelitian, analisis dan pengembangan Layanan.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Penahanan informasi dikumpulkan
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Kami akan menahan Informasi Anda hingga Anda menghapus akun Anda pada Situs Web dan Aplikasi. Penghapusan akun Anda tidak menyebabkan hilangnya Informasi Anda, melainkan hanya berakibatkan pada tidak aktifnya akun Anda untuk sementara waktu.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Keamanan
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Informasi Anda hanya akan disimpan selama diperlukan untuk memenuhi tujuan dari pengumpulannya, atau selama penyimpanan tersebut diperbolehkan oleh peraturan perundang-undangan yang berlaku. Informasi Anda akan dihapus segera setelah dianggap bahwa tujuan pengumpulan Informasi tersebut tidak lagi dibutuhkan sehubungan dengan pemenuhan tujuan bisnis atau hukum.</li>
              <li class="list-style-type-decimal">Kami tidak menjamin keamanan dan pula tidak menjamin bahwa pada data yang Anda berikan tidak akan ditahan/terganggu ketika sedang dikirimkan melalui database Kami. Setiap pengiriman informasi oleh Anda kepada Kami merupakan risiko Anda sendiri. Anda tidak boleh mengungkapkan sandi Anda kepada siapapun. Bagaimanapun efektifnya suatu teknologi, tidak ada sistem keamanan yang tidak dapat ditembus.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Perubahan atas Kebijakan Privasi ini
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Kebijakan Privasi ini dapat berubah sewaktu-waktu baik disebabkan perubahan pada kegiatan bisnis maupun karena pemenuhan ketentuan perundang-undangan yang berlaku. Dalam hal terjadi perubahan pada Kebijakan Privasi ini, maka Kami akan memberitahu kepada Anda melalui surel atau dengan cara pemberitahuan di Situs Web 2 (dua) hari sebelum perubahan dilakukan. Kami menghimbau Anda untuk meninjau halaman ini secara berkala untuk mengetahui informasi terbaru tentang bagaimana ketentuan Kebijakan Privasi ini Kami berlakukan.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Lain-lain
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Kebijakan Privasi ini diatur dan ditafsirkan berdasarkan hukum Negara Republik Indonesia. Setiap dan seluruh sengketa yang timbul dari Kebijakan Privasi ini akan tunduk pada yurisdiksi eksklusif Pengadilan Negeri Surabaya.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Pengakuan dan persetujuan
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Dengan menggunakan Situs Web dan/atau Aplikasi, Anda mengakui bahwa Anda telah membaca dan memahami Kebijakan Privasi ini dan Ketentuan Penggunaan dan setuju dan sepakat terhadap penggunaan, praktek, pemrosesan dan pengalihan informasi pribadi Anda oleh Kami sebagaimana dinyatakan di dalam Kebijakan Privasi ini.</li>
              <li class="list-style-type-decimal">Apabila Informasi yang Anda berikan kepada kami berkaitan dengan Informasi individu lain (seperti Informasi Pribadi yang berkaitan dengan pasangan Anda, anggota keluarga, teman, atau pihak lain), Anda menyatakan dan menjamin bahwa telah memperoleh persetujuan dari individu tersebut dan atas nama individu tersebut menyetujui untuk mengumpulkan, menggunakan, mengungkapkan, dan/atau mengelola Informasi individu tersebut kepada kami.</li>
              <li class="list-style-type-decimal">Anda juga menyatakan bahwa Anda pemilik sah atas seluruh informasi yang telah Anda berikan kepada Kami dan menyetujui untuk memberikan hak kepada Kami dalam menggunakan dan memberikan informasi tersebut kepada Penyedia Layanan.</li>
            </ol>
          </li>
          <li class="list-style-type-upper-alpha font-weight-bold mb-3">
            Cara untuk menghubungi Kami
            <ol class="font-weight-normal">
              <li class="list-style-type-decimal">Jika Anda memiliki pertanyaan lebih lanjut tentang privasi dan keamanan informasi Anda dan ingin memperbarui atau menghapus data Anda maka silahkan hubungi Kami di Darmo Permai Utara XI / 27 Surabaya, Indonesia 60226 atau telepon ke 0813-35535654.</li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>
<style type="scss">
  .list-style-type-upper-alpha {
    list-style-type:upper-alpha;
  }

  .list-style-type-decimal {
    list-style-type:decimal;
  }
</style>
<script>
export default {
  name: 'term.of.use',
  metaInfo() {
    return {
      title: 'Ketentuan privasi',
      meta: [
        { vmid: 'description', name: 'description', content: 'Kebijakan privasi Fourcons' },
        { vmid: 'keyword', name: 'keyword', content: 'sewa alat berat, beli material, rental alat berat, supplier material, kontraktor, konstruksi, pertambangan, indonesia, sewa excavator, sewa bulldozer, sewa crane, beli pasir, beli kayu, beli baja, beli material konstruksi' }
      ]
    }
  },
}
</script>